import React, { useState } from "react";
import Input from "../../components/Input";
import { Tag, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  updateRecipients,
  setRecipients,
  updateDeletedRecipients,
} from "../../redux/caseSlice";
import validator from "validator";
import { useUserContext } from "../../contexts/UserContext";

export default function ReceipentsRecord() {
  const dispatch = useDispatch();
  const { features } = useUserContext();
  const caseRecipients = useSelector((state) => state.case.recipients);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  const addRecipient = () => {
    if (features.talentPerCase === -1) {
      if (!email || !name || !validator.isEmail(email)) return;
      dispatch(
        updateRecipients({
          name,
          email,
        })
      );
      setName("");
      setEmail("");
    } else {
      if (caseRecipients.length + 1 > parseInt(features.talentPerCase)) {
        message.error(
          `You cannot have more thank ${features.talentPerCase} talent(s) per case with your current plan.`
        );
        return;
      } else {
        if (!email || !name || !validator.isEmail(email)) return;
        dispatch(
          updateRecipients({
            name,
            email,
          })
        );
        setName("");
        setEmail("");
      }
    }
  };

  const deleteRecipient = (name, id) => {
    const arr = Array.from(caseRecipients);
    const recipientIndex = arr.findIndex((recip) => recip.name === name);
    arr.splice(recipientIndex, 1);
    dispatch(setRecipients(arr));
    dispatch(updateDeletedRecipients(id));
  };

  const RecipientItem = ({ name, recipientId }) => {
    return (
      <div>
        <Tag
          style={{ fontSize: 12, padding: 10 }}
          closeIcon={<CloseCircleOutlined style={{ fontSize: 12 }} />}
          onClose={() => deleteRecipient(name, recipientId)}
        >
          {name}
        </Tag>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center mt-12 py-12">
      <div className="lg:w-1/3">
        <p className="font-bold mb-2 text-sm text-center">
          Specify the talents you want to get references from.
        </p>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="flex flex-col">
            <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold">
              Talent email
            </label>
            <Input
              placeholder="Email address"
              onChangeText={setEmail}
              value={email}
              styles="w-fit"
            />
            {email && !validator.isEmail(email) && (
              <small className="text-red-500">Enter a valid email.</small>
            )}
            <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mt-5">
              Talent name
            </label>
            <Input
              placeholder="Full name"
              onChangeText={setName}
              value={name}
              styles="w-fit"
            />
          </div>
          <div className="flex items-center justify-between mt-5">
            <button
              className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => addRecipient()}
            >
              Add to list
            </button>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-2 gap-2">
        {caseRecipients.map(({ name, id }, index) => (
          <div key={`${name}-${index}`}>
            <RecipientItem name={name} recipientId={id} />
          </div>
        ))}
      </div>
    </div>
  );
}
