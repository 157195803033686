import React from "react";

export default function Input({
  onChangeText,
  placeholder = "",
  styles = "",
  multiline = false,
  value = null,
  type = "text",
}) {
  if (multiline) {
    return (
      <textarea
        type={type}
        multiple
        value={value}
        className={`block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none ${styles}`}
        placeholder={placeholder}
        onChange={(e) => onChangeText(e.target.value)}
      />
    );
  }
  return (
    <input
      type={type}
      multiple
      value={value}
      className={`block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none ${styles}`}
      placeholder={placeholder}
      onChange={(e) => onChangeText(e.target.value)}
    />
  );
}
