import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useParams } from "react-router-dom";
import FormBuilder from "../../features/FormBuilder";
import { message } from "antd";
import Loading from "../../components/Loading";
import useWindowSize from "../../hooks/useWindowsDimensions";
import * as Sentry from "@sentry/react";
import { ToastContainer, toast } from "react-toastify";
import ScreenSizeBlocker from "../../components/ScreensizeBlocker";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function EditTemplate() {
  usePageTracking();
  let { id } = useParams();
  const [width] = useWindowSize();
  const [template, setTemplate] = useState(null);

  const save = (objs, templateName) => {
    firebase
      .firestore()
      .collection("templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .doc(id)
      .update({
        title: templateName,
        elements: objs,
      })
      .then(() => {
        message.success("Template saved");
      })
      .catch((error) => {
        toast.error("Unable to update template.");
        Sentry.captureException(error);
      });
  };

  async function getTemplate() {
    const template = await firebase
      .firestore()
      .collection("templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .doc(id)
      .get();
    if (template.exists) {
      setTemplate(template.data());
    }
  }

  useEffect(() => {
    getTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (width <= 600) {
    return <ScreenSizeBlocker />;
  }

  if (!template) {
    return (
      <div className="relative md:ml-64 bg-blueGray-100">
        <Loading />
      </div>
    );
  }
  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      <ToastContainer />
      <FormBuilder
        save={save}
        savedElements={template.elements}
        formName={template.title}
      />
    </div>
  );
}
