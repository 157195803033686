export function headerHTML(body = '') {
    return (
        `
<table id="u_body"
    style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #f9f9f9;width:100%"
    cellpadding="0" cellspacing="0">
    <tbody>
        <tr style="vertical-align: top">
            <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #f9f9f9;"><![endif]-->


                <div class="u-row-container" style="padding: 0px;background-color: #f9f9f9">
                    <div class="u-row"
                        style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #f9f9f9;">
                        <div
                            style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #f9f9f9;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #f9f9f9;"><![endif]-->

                            <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                            <div class="u-col u-col-100"
                                style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                <div style="height: 100%;width: 100% !important;">
                                    <!--[if (!mso)&(!IE)]><!-->
                                    <div
                                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:15px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <table height="0px" align="center" border="0" cellpadding="0"
                                                            cellspacing="0" width="100%"
                                                            style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #f9f9f9;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                            <tbody>
                                                                <tr style="vertical-align: top">
                                                                    <td
                                                                        style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                        <span>&nbsp;</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!--[if (!mso)&(!IE)]><!-->
                                    </div><!--<![endif]-->
                                </div>
                            </div>
                            <!--[if (mso)|(IE)]></td><![endif]-->
                            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                        </div>
                    </div>
                </div>



                <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                        style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                        <div
                            style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #ffffff;"><![endif]-->

                            <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                            <div class="u-col u-col-100"
                                style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                <div style="height: 100%;width: 100% !important;">
                                    <!--[if (!mso)&(!IE)]><!-->
                                    <div
                                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:25px 10px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="padding-right: 0px;padding-left: 0px;"
                                                                        align="center">

                                                                        <img align="center" border="0"
                                                                            src="https://res.cloudinary.com/makemeup/image/upload/v1696536041/hacker_idnqqq.png"
                                                                            alt="Image" title="Image"
                                                                            style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: 10vh;float: none;width: 6vw;max-width: 168.2px; margin-bottom: 10px;"
                                                                            width="168.2">
                                                                        <p>Your company image above.</p>

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!--[if (!mso)&(!IE)]><!-->
                                    </div><!--<![endif]-->
                                </div>
                            </div>
                            <!--[if (mso)|(IE)]></td><![endif]-->
                            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                        </div>
                    </div>
                </div>



                <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                        style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #38D1C5;">
                        <div
                            style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #161a39;"><![endif]-->

                            <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                            <div class="u-col u-col-100"
                                style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                <div style="height: 100%;width: 100% !important;">
                                    <!--[if (!mso)&(!IE)]><!-->
                                    <div
                                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:35px 10px 10px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                            <tbody>
          
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 10px 30px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <div
                                                            style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                            <p
                                                                style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                <span
                                                                    style="font-size: 28px; line-height: 39.2px; color: #ffffff; font-family: Lato, sans-serif;">You have been be requested to ***********</span></p>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!--[if (!mso)&(!IE)]><!-->
                                    </div><!--<![endif]-->
                                </div>
                            </div>
                            <!--[if (mso)|(IE)]></td><![endif]-->
                            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                        </div>
                    </div>
                </div>



                <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                        style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                        <div
                            style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #ffffff;"><![endif]-->

                            <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                            <div class="u-col u-col-100"
                                style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                <div style="height: 100%;width: 100% !important;">
                                    <!--[if (!mso)&(!IE)]><!-->
                                    <div
                                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:40px 40px 30px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <div
                                                            style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                            <p style="font-size: 14px; line-height: 140%;"><span
                                                                    style="font-size: 18px; line-height: 25.2px; color: #666666;">Hello, {recipient name}</span>
                                                            </p>
                                                            <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                            <!-- <p style="font-size: 14px; line-height: 140%;"><span
                                                            style="font-size: 18px; line-height: 25.2px; color: #666666;">You are requested to submit your reference contact information so we can reach out.</span></p>-->
                                                            <div style="font-size: 18px; line-height: 25.2px; color: #666666;">${body}</div>
                                                            <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                            <p style="font-size: 14px; line-height: 140%;"><span
                                                                    style="font-size: 18px; line-height: 25.2px; color: #666666;">To get started, please follow the link below:
                                                                </span></p>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 40px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <!--[if mso]><style>.v-button {background: transparent !important;}</style><![endif]-->
                                                        <div align="left">
                                                            <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:51px; v-text-anchor:middle; width:205px;" arcsize="2%"  stroke="f" fillcolor="#18163a"><w:anchorlock/><center style="color:#FFFFFF;font-family:'Lato',sans-serif;"><![endif]-->
                                                            <a href="${origin}/" target="_blank" class="v-button"
                                                                style="box-sizing: border-box;display: inline-block;font-family:'Lato',sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #38D1C5; border-radius: 1px;-webkit-border-radius: 1px; -moz-border-radius: 1px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;font-size: 14px;">
                                                                <span
                                                                    style="display:block;padding:15px 40px;line-height:120%;"><span
                                                                        style="font-size: 18px; line-height: 21.6px;">Get started</span></span>
                                                            </a>
                                                            <!--[if mso]></center></v:roundrect><![endif]-->
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:40px 40px 30px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <div
                                                            style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                            <p style="font-size: 14px; line-height: 140%;"><span
                                                                    style="color: #888888; font-size: 14px; line-height: 19.6px;"><em><span
                                                                            style="font-size: 16px; line-height: 22.4px;">Please
                                                                            ignore this email if you don't know what this is about.</span></em></span><br><span
                                                                    style="color: #888888; font-size: 14px; line-height: 19.6px;"><em><span
                                                                            style="font-size: 16px; line-height: 22.4px;">&nbsp;</span></em></span>
                                                            </p>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!--[if (!mso)&(!IE)]><!-->
                                    </div><!--<![endif]-->
                                </div>
                            </div>
                            <!--[if (mso)|(IE)]></td><![endif]-->
                            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                        </div>
                    </div>
                </div>






                <div class="u-row-container" style="padding: 0px;background-color: transparent">
                    <div class="u-row"
                        style="Margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #f9f9f9;">
                        <div
                            style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:600px;"><tr style="background-color: #f9f9f9;"><![endif]-->

                            <!--[if (mso)|(IE)]><td align="center" width="600" style="width: 600px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                            <div class="u-col u-col-100"
                                style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                                <div style="height: 100%;width: 100% !important;">
                                    <!--[if (!mso)&(!IE)]><!-->
                                    <div
                                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                        <!--<![endif]-->

                                        <table style="font-family:'Lato',sans-serif;" role="presentation"
                                            cellpadding="0" cellspacing="0" width="100%" border="0">
                                            <tbody>
                                                <tr>
                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 40px 30px 20px;font-family:'Lato',sans-serif;"
                                                        align="left">

                                                        <div
                                                            style="line-height: 140%; text-align: left; word-wrap: break-word;">

                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!--[if (!mso)&(!IE)]><!-->
                                    </div><!--<![endif]-->
                                </div>
                            </div>
                            <!--[if (mso)|(IE)]></td><![endif]-->
                            <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                        </div>
                    </div>
                </div>


                <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
            </td>
        </tr>
    </tbody>
</table>
`

    )
}