import React from "react";
import FormViewer from "../components/FormViewer";

export default function Preview({ elements = [], handleCancel, title = "" }) {
  return (
    <div>
      <FormViewer
        elementsArray={elements}
        handleCancel={handleCancel}
        title={title}
      />
    </div>
  );
}
