import React from "react";
import Navbar from "../components/Navbar";
import Cases from "./cases/Cases";
import usePageTracking from "../hooks/usePageTracking";

export default function NewHome() {
  usePageTracking();
  return (
    <div>
      <Navbar />
      <div className="relative bg-white">
        <Cases />
      </div>
    </div>
  );
}
