import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Card, Modal, Tour } from "antd";
import { useNavigate } from "react-router-dom";
import Preview from "../../features/Preview";
import { useUserContext } from "../../contexts/UserContext";
import useWindowSize from "../../hooks/useWindowsDimensions";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";
const { Meta } = Card;

export default function ReadyMadeTemplates() {
  usePageTracking();
  const navigate = useNavigate();
  const [width,] = useWindowSize();
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [, toggleLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTour, toggleShowTour] = useState(false);
  const { updateUserDetails } = useUserContext();
  async function shouldShowTour() {
    const user = await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get();
    if (user.exists) {
      const userDetails = user.data();
      if (!userDetails?.isCustomTemplateTour) {
        toggleShowTour(true);
      }
    }
  }
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: "Readymade templates",
      description:
        "You can select from our list of templates which to reuse.",
      target: () => ref1.current,
    },
    {
      title: "Next steps",
      description:
        "Select one from the list. You can also edit these forms to your taste and simply claim them as your own.",
      target: () => ref2.current,
    },
  ];

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  async function getTemplates() {
    try {
      toggleLoading(true);
      const templates = await firebase
        .firestore()
        .collection("templates")
        .doc("readymade")
        .collection("list")
        .get();
      if (!templates.empty) {
        const arr = [];
        templates.forEach((template) => {
          arr.push({
            ...template.data(),
            id: template.id,
          });
        });
        setTemplates(arr);
        toggleLoading(false);
      }
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
    }
  }

  const editForm = (id) => {
    navigate(`/templates/usetemplate/${id}`);
  };

  useEffect(() => {
    shouldShowTour();
    getTemplates();
  }, []);

  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      <div className="flex flex-row px-6 border border-solid border-blueGray-100 py-3 uppercase border-l-0 border-r-0 whitespace-nowrap">
        <h1 className="lg:text-2xl lg:ml-5 ml-2 mt-3 font-bold">
          Readymade templates
        </h1>
      </div>
      {currentTemplate && (
        <Modal
          onCancel={handleCancel}
          onOk={handleOk}
          open={isModalOpen}
          // title={currentTemplate.title}
          footer={null}
        >
          <div>
            <Preview
              elements={currentTemplate.elements}
              handleCancel={handleCancel}
              // title={currentTemplate.title}
            />
          </div>
        </Modal>
      )}
      <div className="lg:grid lg:grid-cols-4">
        {templates.map((template) => (
          <Card
            key={template.id}
            style={{ width: 300, margin: 20 }}
            actions={[
              <>
                {width >= 600 && (
                  <EyeOutlined
                    key="preview"
                    onClick={() => {
                      setCurrentTemplate(template);
                      setIsModalOpen(true);
                    }}
                  />
                )}
              </>,
              <EditOutlined key="edit" onClick={() => editForm(template.id)} />,
            ]}
          >
            <Meta title={template.title} description="" />
          </Card>
        ))}
      </div>
      <Tour
        open={showTour}
        onClose={() => {
          updateUserDetails({
            isCustomTemplateTour: true,
          });
          toggleShowTour(false);
        }}
        mask={false}
        type="primary"
        steps={steps}
      />
    </div>
  );
}
