import React from "react";
import { Button, Empty, Tooltip } from "antd";
import { RecipientStatus } from "../../utils/enums";
export default function TalentList({
  recipients = [],
  referees = [],
  stages = [],
  maxReferreNumber = 0,
  sendRecipientReminder,
  sendCompleteFunction,
  sendReminders,
}) {
  return (
    <div className="w-full xl:w-fit mb-12 xl:mb-0 px-4">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Talent list
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={() => sendReminders()}
              >
                Send reminders to all
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Enroll date
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  stage
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  status
                </th>
                {/* <th
                  className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  style={{ minWidth: "140px" }}
                >
                  % referee response
                </th> */}
                <th
                  className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                  style={{ minWidth: "140px" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {recipients.map((recipient) => {
                /* const recipientRefereeResponses = referees.filter(
                  (referee) =>
                    referee.talentName === recipient.name &&
                    referee.status === RefereeStatus.RESPONSE_RECEIVED
                ).length;
                const percentCompletion =
                  (recipientRefereeResponses / maxReferreNumber) * 100;
                const val = !isNaN(percentCompletion)
                  ? percentCompletion.toFixed(0)
                  : 0; */
                return (
                  <tr>
                    <th className="border-t-0 cursor-pointer px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      <Tooltip title={`${recipient.email}`}>
                        <span> {recipient.name}</span>
                      </Tooltip>
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left font-normal">
                      {new Date(recipient.createdAt).toDateString()}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left font-normal">
                      {recipient.status === RecipientStatus.COMPLETE ? (
                        <spa>No stages left</spa>
                      ) : (
                        <span>{stages[recipient.stage].title}</span>
                      )}
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {recipient.status ===
                        RecipientStatus.RECIPIENT_CREATED && (
                        <span>Referee request not sent</span>
                      )}
                      {recipient.status ===
                        RecipientStatus.USER_REQUESTED_FOR_REFEREE_DATA && (
                        <span>
                          Request for user to submit referee details sent
                        </span>
                      )}
                      {(recipient.status ===
                        RecipientStatus.USER_PROVIDED_REFEREE_DATA ||
                        recipient.status ===
                          RecipientStatus.USER_REFEREE_REQUESTED_FOR_RESPONSE) && (
                        <span>
                          User has submitted referee details / requests sent to
                          referees{" "}
                        </span>
                      )}
                      {recipient.status ===
                        RecipientStatus.USER_REFEREE_RESPONDED && (
                        <span>
                          At least one referee has submitted a response for the
                          current stage
                        </span>
                      )}
                      {recipient.status === RecipientStatus.COMPLETE && (
                        <span>Completed</span>
                      )}
                    </td>
                    {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <div className="relative w-full">
                          <Progress percent={val} status="active" />
                        </div>
                      </div>
                    </td> */}
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left font-normal">
                      {recipient.status !==
                        RecipientStatus.USER_REFEREE_RESPONDED &&
                        recipient.status !== RecipientStatus.COMPLETE && (
                          <Button
                            onClick={() =>
                              sendRecipientReminder &&
                              sendRecipientReminder([recipient])
                            }
                          >
                            Send reminder
                          </Button>
                        )}
                      {recipient.status === RecipientStatus.COMPLETE && (
                        <Button
                          onClick={() =>
                            sendCompleteFunction &&
                            sendCompleteFunction(recipient)
                          }
                        >
                          Close recipient
                        </Button>
                      )}
                      {recipient.status === 4 && <span>Completed</span>}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* {recipients.length > 0 && (
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                See all
              </button>
            </div>
          )} */}
        </div>
        {recipients.length === 0 && (
          <div className="flex flex-row justify-center py-8">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}
