import React from "react";
import usePageTracking from "../hooks/usePageTracking";

export default function TermsAndConditions() {
  usePageTracking();
  return (
    <div className="bg-gray-100 p-8">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-md shadow-md">
        <h1 className="text-3xl font-bold mb-6">Welcome to Your Botref!</h1>

        <p className="mb-6">
          Thank you for choosing Botref, a cutting-edge reference management web
          platform. By accessing or using our services, you agree to comply with
          and be bound by the following terms and conditions. If you do not
          agree to these terms, please do not use our platform.
        </p>

        <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
        <p className="mb-6">
          By using Botref, you agree to these Terms and Conditions. If you
          disagree with any part of these terms, you may not use our services.
        </p>

        <h2 className="text-2xl font-bold mb-4">2. Definitions</h2>
        <div className="mb-6">
          <ul className="list-disc pl-6 mb-4">
            <li>This refers to the web platform.</li>
            <li>The platform: This refers to this web site called Botref.</li>
            <li>
              Billings: This refers to payment made for any plan within this web
              platform.
            </li>
            <li>
              Cases: This refers to a reference collection round where account
              users attempt to retrieve reference information from users.
            </li>
            <li>
              Templates: This refers to the form from with these references and
              other informarion necesssary for the case collection to be
              complete. users.
            </li>
          </ul>
        </div>

        <h2 className="text-2xl font-bold mb-4">3. User Accounts</h2>
        <p className="mb-6">
          To access certain features Botref, you may be required to create an
          account. You are responsible for maintaining the confidentiality of
          your account and password. We reserve the right to terminate accounts
          or restrict access in our sole discretion.
        </p>

        <h2 className="text-2xl font-bold mb-0">4. User Conduct</h2>
        <p className="my-2">Users agree not to:</p>
        <div className="mb-6">
          <ul className="list-disc pl-6 mb-4">
            <li>Violate any laws or regulations.</li>
            <li>Infringe on the intellectual property rights of others.</li>
            <li>
              Transmit harmful code or interfere with the functionality of the
              platform.
            </li>
          </ul>
        </div>

        <h2 className="text-2xl font-bold mb-4">5. Intellectual Property</h2>
        <p className="mb-6">
          Botref retains ownership of all intellectual property rights on the
          platform. Users retain ownership of their content but grant Botref a
          license to use, reproduce, and distribute that content.
        </p>

        <h2 className="text-2xl font-bold mb-4">
          6. Data Protection and Privacy
        </h2>
        <p className="mb-6">
          Please refer to our Privacy Policy:{" "}
          <a
            target="_blank"
            className="underline text-blue-400 font-bold"
            href="/privacy-policy"
          >
            privacy policy
          </a>{" "}
          for information on how we collect, use, and disclose user information.
        </p>

        <h2 className="text-2xl font-bold mb-4">7. Payment and Billing</h2>
        <div className="mb-6">
          <ul className="list-disc pl-6 mb-4">
            <li>Pricing information is available on our website.</li>
            <li>Payment is required to access certain features.</li>
            <li>Automatic renewal is enabled unless canceled by the user.</li>
          </ul>
        </div>

        <h2 className="text-2xl font-bold mb-4">8. Disclaimers</h2>
        <p className="mb-6">
          Botref is provided "as-is" without any warranties. We do not guarantee
          the accuracy or reliability of the platform.
        </p>

        <h2 className="text-2xl font-bold mb-4">9. Termination</h2>
        <p className="mb-6">
          Botref reserves the right to terminate or suspend accounts for
          violation of these terms.
        </p>

        <h2 className="text-2xl font-bold mb-4">10. Changes to Terms</h2>
        <p className="mb-6">
          We may update these terms from time to time. Users will be notified of
          significant changes.
        </p>

        {/* <h2 className="text-2xl font-bold mb-4">11. Governing Law and Dispute Resolution</h2>
        <p className="mb-6">
        These terms are governed by [Your Jurisdiction]'s laws. Any disputes will be resolved through arbitration.
        </p> */}

        <h2 className="text-2xl font-bold mb-4">11. Limitation of Liability</h2>
        <p className="mb-6">
          To the fullest extent permitted by law, Botref shall not be liable for
          any direct, indirect, incidental, consequential, or punitive damages.
        </p>

        <h2 className="text-2xl font-bold mb-4">12. Indemnification</h2>
        <p className="mb-6">
          Users agree to indemnify and hold Botref harmless from any claims,
          damages, or losses.
        </p>

        <h2 className="text-2xl font-bold mb-4">13. Force Majeure</h2>
        <p className="mb-6">
          Botref is not liable for any failure or delay in performance resulting
          from causes beyond its reasonable control.
        </p>

        <h2 className="text-2xl font-bold mb-4">14. Miscellaneous</h2>
        <div className="mb-6">
          <ul className="list-disc pl-6 mb-4">
            <li>
              If any provision is found to be unenforceable, it will not affect
              the validity of other provisions.
            </li>
            <li>
              These terms constitute the entire agreement between users and
              Botref.
            </li>
          </ul>
        </div>

        <h2 className="text-2xl font-bold mb-4">17. Contact Information</h2>
        <p>
          If you have any questions about these terms, please contact us at{" "}
          <a href="mailto:admin@botref.com" className="text-blue-500">
            admin@botref.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
