import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { pricing } from "../../utils/pricingData";
import firebase from "firebase/app";
import "firebase/auth";
import api from "../../api";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";

function PaymentForm() {
  usePageTracking();
  const navigate = useNavigate();
  let { plan } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [planData, setPlanData] = useState(null);
  const [email, setEmail] = useState(firebase.auth().currentUser.email || null);
  const [fullName, setFullName] = useState(
    firebase.auth().currentUser.displayName || null
  );

  async function getPlanData() {
    const data = pricing.items.filter((pl) => pl.name === plan);
    setPlanData(data[0]);
  }

  useEffect(() => {
    getPlanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  async function saveInvoicesBillings(subscriptionId) {
    const invoiceObject = {
      amount: planData.price,
      plan: plan,
      createAt: new Date().toISOString(),
      subscriptionId: subscriptionId,
    };
    await firebase
      .firestore()
      .collection("invoices")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .add({
        ...invoiceObject,
      })
      .then(async () => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        await firebase
          .firestore()
          .collection("users")
          .doc(firebase.auth().currentUser.uid)
          .update({
            plan: plan,
            nextPayment: date,
            subscriptionId: subscriptionId,
          })
          .then(() => {
            toast.success("Invoice saved successfully");
            navigate("/billings");
          })
          .catch((err) => Sentry.captureException(err));
      })
      .catch((error) => {
        toast.error(
          "Unable to save your invoice, please reach out to support with this and we will follow up."
        );
        Sentry.captureException(error);
      });
  }

  const createSubscription = async () => {
    try {
      // create a payment method
      toast.loading("Please wait.");
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name: firebase.auth().currentUser.displayName || "",
          email: firebase.auth().currentUser.email,
        },
      });

      const postBody = {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: firebase.auth().currentUser.displayName || "",
        email: firebase.auth().currentUser.email,
        priceId: planData.planId,
      };

      const response = (await api.post("/payment/createSubscription", postBody))
        .data;

      const confirmPayment = await stripe?.confirmCardPayment(
        response.clientSecret
      );

      if (confirmPayment?.error) {
        toast.error(confirmPayment.error.message);
      } else {
        toast.loading("Success! saving your invoice");
        await saveInvoicesBillings(response.subscriptionId);
      }
    } catch (error) {
      toast.error("Something went wrong");
      Sentry.captureException(error);
    }
  };

  if (planData) {
    return (
      <div className="relative md:ml-64 p-12">
        <div onClick={() => {
          window.history.back();
        }} className="border w-fit rounded-full py-2 px-4 lg:mb-12 cursor-pointer">X</div>
        <ToastContainer />
        <div className="flex flex-col items-center bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
          <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
            <p className="text-xl font-medium">Payment Details</p>
            <p className="text-gray-400">
              Complete this process for the {plan} plan by providing your payment details for the.
            </p>
            <div className="">
              <label
                htmlFor="email"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Email
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  name="email"
                  className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="your.email@gmail.com"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </div>
              </div>
              <label
                htmlFor="card-holder"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Card Holder
              </label>
              <div className="relative">
                <input
                  type="text"
                  id="card-holder"
                  name="card-holder"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Your full name here"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                    />
                  </svg>
                </div>
              </div>
              <label
                htmlFor="card-no"
                className="mt-4 mb-4 block text-sm font-medium"
              >
                Card Details
              </label>
              <CardElement />
              <div className="flex flex-col sm:flex-row"></div>
              {/* <div className="mt-6 border-t border-b py-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-gray-900">Subtotal</p>
                  <p className="font-semibold text-gray-900">
                    ${planData.price}
                  </p>
                </div>
              </div> */}
              <div className="mt-6 flex items-center justify-between border-t">
                <p className="text-sm font-medium text-gray-900">Total</p>
                <p className="text-2xl font-semibold text-gray-900">
                  ${planData.price}
                </p>
              </div>
            </div>
            <button
              onClick={() => createSubscription()}
              className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
            >
              Make payment
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentForm;
