import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import PasswordInput from "../components/PasswordInput";
import validator from "validator";
import * as Sentry from "@sentry/react";
import api from "../api";
import usePageTracking from "../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/database";
import "firebase/firebase-firestore";

export default function SignUp() {
  usePageTracking();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [loading, toggleLoading] = useState(false);

  function validateString(inputString) {
    if (typeof inputString !== "string") {
      return "Input is not a string.";
    }

    if (inputString.length < 4) {
      return "String is too short. It must have at least 4 characters.";
    }

    // eslint-disable-next-line no-useless-escape
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(inputString)) {
      return "String must contain at least one symbol (!@#$%^&*()_+{}[]:;<>,.?~\\-).";
    }

    return "Validation successful";
  }

  function validateEmail() {
    return validator.isEmail(email);
  }

  useEffect(() => {
    if (!validateEmail()) {
      setEmailErrorMessage("Your email is not correct");
    } else {
      setEmailErrorMessage(null);
    }
    const validatePassword = validateString(password);
    if (validatePassword !== "Validation successful") {
      setPasswordErrorMessage(validatePassword);
    } else {
      setPasswordErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  async function doSignUp() {
    try {
      toggleLoading(true);
      if (!email || !password || !fullName) {
        toggleLoading(false);
        toast.error("Please fill the form correctly. Thanks");
        return;
      }
      if (!validator.isEmail(email)) {
        toggleLoading(false);
        toast.error("Your email is not valid");
        return;
      }
      toast.loading('Please wait');
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          firebase
            .firestore()
            .collection("users")
            .doc(`${user.user.uid}`)
            .set({
              plan: "Free",
              createdAt: new Date().toISOString(),
              email,
              fullName,
              notifications: [],
            })
            .then(async () => {
              await api.post("/payment/createcustomer", {
                fullName: fullName,
                email: email,
              });
              await firebase.auth().currentUser.updateProfile({
                displayName: fullName
              })
              toggleLoading(false);
              navigate("/templates");
            });
        })
        .catch((error) => {
          toggleLoading(false);
          if (error.code === "auth/wrong-password") {
            toast.error("Please check the Password");
            return;
          }
          if (error.code === "auth/user-not-found") {
            toast.error("Please check the Email");
            return;
          }
          if (error.code === "auth/email-already-in-use") {
            toast.error("Email Already in Use");
            return;
          }
          toast.error(error);
        });
    } catch (error) {
      toggleLoading(false);
      Sentry.captureException(error);
    }
  }
  return (
    <div>
      <ToastContainer />
      <section class="bg-gray-50 dark:bg-gray-900 h-screen">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              class="w-14 h-14 mr-2"
              src="https://res.cloudinary.com/makemeup/image/upload/v1701291581/logo_qg5ehf.png"
              alt="botreflogo"
            />
            Botref
          </Link>
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create your account
              </h1>
              <div class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your full name
                  </label>
                  <input
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    type="fullName"
                    name="fullName"
                    id="fullNAME"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Full name"
                    required=""
                  />
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-5"
                  >
                    Your email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    name="email"
                    id="email"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="your@email.com"
                    required=""
                  />
                  {emailErrorMessage && (
                    <small className="text-red-500">{emailErrorMessage}</small>
                  )}
                </div>
                <div>
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <PasswordInput onChangeText={setPassword} value={password} />
                  {passwordErrorMessage && (
                    <small className="text-red-500">
                      {passwordErrorMessage}
                    </small>
                  )}
                </div>
                <button
                  onClick={() => doSignUp()}
                  className="w-full bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ml-auto"
                >
                  {loading ? <span>Please wait...</span> : <span>Sign up</span>}
                </button>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have an account yet?{" "}
                  <Link
                    to="/login"
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
