export const RecipientStatus = {
  RECIPIENT_CREATED: "RECIPIENT_CREATED",
  USER_REFEREE_NOT_RESPONDED: "USER_REFEREE_NOT_RESPONDED",
  USER_REFEREE_RESPONDED: "REFEREE_RESPONDED",
  USER_REQUESTED_FOR_REFEREE_DATA: "USER_REQUESTED_FOR_REFEREE_DATA",
  USER_PROVIDED_REFEREE_DATA: "USER_PROVIDED_REFEREE_DATA",
  USER_REFEREE_REQUESTED_FOR_RESPONSE: "USER_REFEREE_REQUESTED_FOR_RESPONSE",
  COMPLETE: "COMPLETE",
};

export const CaseStatus = {
  STARTED: "STARTED",
  COMPLETE: "COMPLETE",
  DELETED: "DELETED",
};

export const RefereeStatus = {
  REFEREE_CREATED: "REFEREE_CREATED",
  RESPONDED_NOT_SUBMITTED: "RESPONDED_NOT_SUBMITTED",
  RESPONSE_REQUESTED: "RESPONSE_REQUESTED",
  RESPONSE_RECEIVED: "RESPONSE_RECEIVED",
  COMPLETE: "COMPLETE",
};
