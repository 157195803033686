import React from "react";
import CaseWizard from "../../features/casewizard/CaseWizard";
import usePageTracking from "../../hooks/usePageTracking";

export default function NewCase() {
  usePageTracking();
  return (
    <div className="relative md:ml-64 bg-blueGray-100 p-5">
      <CaseWizard />
    </div>
  );
}
