import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipients: [],
  deletedRecipients: [],
  deletedStages: [],
  templates: [],
  emailTemplates: [],
  stages: [],
  caseData: {},
};

export const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    updateCaseData: (state, action) => {
      const data = action.payload;
      state.caseData = {
        ...state.caseData,
        ...data,
      };
    },
    resetCaseData: (state) => {
      state.caseData = {};
      state.deletedRecipients = [];
      state.recipients = [];
      state.deletedStages = [];
      state.templates = [];
      state.emailTemplates = [];
      state.stages = [];
    },
    updateRecipients: (state, action) => {
      state.recipients.push(action.payload);
    },
    updateStages: (state, action) => {
      state.stages.push(action.payload);
    },
    updateDeletedRecipients: (state, action) => {
      state.deletedRecipients.push(action.payload);
    },
    updateDeletedStages: (state, action) => {
      state.deletedStages.push(action.payload);
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    setStage: (state, action) => {
      state.stages = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setEmailTemplates: (state, action) => {
      state.emailTemplates = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCaseData,
  updateRecipients,
  setRecipients,
  setTemplates,
  setEmailTemplates,
  updateDeletedRecipients,
  resetCaseData,
  setStage,
  updateStages,
  updateDeletedStages,
} = caseSlice.actions;

export default caseSlice.reducer;
