export default function ButtonComp({
  onClick,
  title = "",
  backgroundColor = "bg-blue-500",
  styles = "",
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      className={`${backgroundColor} text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ${styles}`}
      onClick={() => onClick()}
    >
      {title}
    </button>
  );
}
