import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Select, message } from "antd";
import {
  updateStages,
  setStage,
  updateDeletedStages,
} from "../../redux/caseSlice";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const { Meta } = Card;

export default function CaseStages() {
  const dispatch = useDispatch();
  const caseStages = useSelector((state) => state.case.stages);
  const [caseStageState, setCaseStageState] = useState(caseStages);
  const templates = useSelector((state) => state.case.templates);
  const [stageTitle, setStageTitle] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [, setSelectedEmailTemplateId] = useState(null);
  const [updatedStageId, setUpdateStageId] = useState(null);
  const [order, updateOrder] = useState(0);

  useEffect(() => {
    function giveOrderTolist() {
      const arr = [];
      caseStages.forEach((caseStage, index) => {
        arr.push({
          ...caseStage,
          order: index,
        });
      });
      setCaseStageState(arr);
    }
    giveOrderTolist();
  }, [caseStages]);

  const onTemplateChange = (value) => {
    setSelectedTemplateId(value);
  };

  const deleteRecipient = (stageTitle, id) => {
    const arr = Array.from(caseStages);
    const stageIndex = arr.findIndex((recip) => recip.title === stageTitle);
    arr.splice(stageIndex, 1);
    dispatch(setStage(arr));
    dispatch(updateDeletedStages(id));
  };

  const StageItem = ({ title, stageId }) => {
    return (
      <div>
        <Card
          style={{ width: 250, margin: 20, marginRight: 0 }}
          key={stageId}
          actions={[
            <DeleteOutlined
              key="trash"
              onClick={() => {
                deleteRecipient(title, stageId);
              }}
            />,
            <EditOutlined
              key="edit"
              onClick={() => {
                const selectedStage = Array.from(caseStages).find(
                  (item) => item.id === stageId
                );
                console.log("abc", selectedStage);
                if (selectedStage) {
                  setUpdateStageId(stageId);
                  setSelectedTemplateId(selectedStage.templateId);
                  setSelectedEmailTemplateId(selectedStage.emailTemplate);
                  setStageTitle(selectedStage.title);
                }
              }}
            />,
          ]}
        >
          <Meta title={title} />
        </Card>
      </div>
    );
  };

  function addStage() {
    if (!selectedTemplateId || !stageTitle) {
      message.error("Please select items from all fields.");
      return;
    }
    if (!updatedStageId) {
      dispatch(
        updateStages({
          title: stageTitle,
          templateId: selectedTemplateId,
          order: order,
        })
      );
      updateOrder(order + 1);
    } else {
      const arr = Array.from(caseStages);
      const selectedStageIndex = arr.findIndex(
        (item) => item.id === updatedStageId
      );
      const update = {
        id: updatedStageId,
        title: stageTitle,
        templateId: selectedTemplateId,
        order: arr[selectedStageIndex].order,
      };
      arr.splice(selectedStageIndex, 1, update);
      dispatch(setStage(arr));
    }
    setStageTitle("");
  }

  return (
    <div className="flex flex-col mt-5 py-12">
      <p className="text-center mb-10">
        <b>Case Stages:</b> Describe indepth what stages will be involved in this
        case and what template will be sent out for your talents at each stage.
        <br />
        <small>
          E.g. Stage 1: Personal referee gathering. <br />
          Stage 2: Official referee gathering
        </small>
      </p>
      <div className="lg:grid lg:grid-cols-3 mx-24">
        <div>
          <Card
            bordered={false}
            style={{
              paddingBottom: 30,
              marginTop: 10,
            }}
          >
            <div className="flex flex-col">
              <p className="text-xl mb-3">Stage details</p>
              <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mb-1">
                Give this stage a name:
              </label>
              <input
                className="border-2 rounded-md px-5 h-10"
                placeholder="E.g. Personal reference stage"
                value={stageTitle}
                onChange={(e) => setStageTitle(e.target.value)}
              />
              <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mb-1 mt-3">
                Select questionnaire template for this stage
              </label>
              <Select
                showSearch
                value={selectedTemplateId}
                defaultValue={selectedTemplateId}
                placeholder="Select a template"
                optionFilterProp="children"
                onChange={onTemplateChange}
                style={{ width: 170, alignSelf: "flex-start" }}
                options={templates.map((template) => {
                  return {
                    value: template.id,
                    label: template.title,
                  };
                })}
              />
            </div>
            <div className="flex items-center justify-between mt-5">
              <button
                className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => addStage()}
              >
                Add stage to list
              </button>
            </div>
          </Card>
        </div>
        {caseStages.length > 0 && (
          <div className="lg:flex lg:flex-row lg:justify-center mt-32 hidden">
            <i className="fas fa-arrow-right text-emerald-500 text-4xl"></i>
          </div>
        )}
        {caseStages.length > 0 && (
          <div>
            <Card
              bordered={false}
              style={{
                paddingBottom: 30,
                marginTop: 10,
              }}
            >
              <div>
                <span>User flow will progress and present templates in this order:</span>
                <div className="mt-2">
                  {caseStageState.map(({ title, id, order }, index) => (
                    <div key={`${id}${index}`}>
                      <div>
                        <div className="flex flex-row">
                          <p>{order + 1}</p>
                          <StageItem title={title} stageId={id} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
}
