import React, { useState } from "react";
import { useUserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { pricing } from "../utils/pricingData";
import { Modal } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Pricing({ showButton = false }) {
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const { items } = pricing;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate(`/billings/checkout/${selectedPlan}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigateToPayment = (plan) => {
    if (userDetails.plan === plan) {
      toast.info(`You are already on the ${plan} plan.`);
      return;
    }
    setSelectedPlan(plan);
    if (userDetails.plan !== "Free") {
      showModal();
    } else {
      navigate(`/billings/checkout/${plan}`);
    }
  };

  if (!userDetails) return null;

  return (
    <div>
      <ToastContainer />
      <Modal
        title="Change plan?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style:{backgroundColor: 'blue'} }}
      >
        <p>
          You already have an active plan. You can change if you choose or you
          can wait till its about to expire.
        </p>
      </Modal>
      <section className={`bg-background`} id="pricing">
        <div className={`container mx-auto px-2 pb-12 text-primary`}>
          <div
            className={`flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4`}
          >
            {items.map((item) => (
              <div
                className={`flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-background mt-4`}
              >
                <div
                  className={`flex-1 bg-background text-gray-600 rounded-t rounded-b-none overflow-hidden shadow`}
                >
                  <div
                    className={`p-8 text-3xl font-bold text-center border-b-4`}
                  >
                    {item?.name}
                  </div>
                  <ul className={`w-full text-center text-sm`}>
                    {item?.features.map((feature) => (
                      <li
                        className={`border-b py-4`}
                        key={`${item.name}-${feature}`}
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}
                >
                  <div
                    className={`w-full pt-6 text-3xl text-gray-600 font-bold text-center`}
                  >
                    ${item?.price}
                    <span className={`text-base`}> {item?.priceDetails}</span>
                  </div>
                  {showButton && (
                    <div className="w-fit mx-auto mt-3">
                      <button
                        onClick={() => navigateToPayment(item?.name)}
                        className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
                      >
                        <span>Select plan</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
