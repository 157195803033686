import React from "react";
import Pricing from "../../components/Pricing";
import { useNavigate } from "react-router";
import { useUserContext } from "../../contexts/UserContext";
import usePageTracking from "../../hooks/usePageTracking";

export default function PricingPage() {
  usePageTracking();
  const { userDetails } = useUserContext();
  const navigate = useNavigate();
  return (
    <div className="relative md:ml-64">
      <div className="flex flex-row mt-10">
        <div
          className="lg:ml-12 mb-10 lg:mt-2 cursor-pointer lg:block hidden"
          onClick={() => navigate(`/billings`)}
        >
          <i className="fas fa-arrow-left text-emerald-500 mr-4 ml-2"></i>
        </div>
      </div>
      <h1
          className={`w-full text-5xl font-bold leading-tight text-center text-primary`}
        >
          Pricing
        </h1>
      <p className="mr-auto text-sm text-navy-700 dark:text-white font-bold my-2 text-center">
        Your current plan: Botref {userDetails.plan}
      </p>
      <div className={`w-full mb-4`}>
        <div
          className={`h-1 mx-auto bg-primary w-64 opacity-25 my-0 py-0 rounded-t`}
        ></div>
      </div>
      <Pricing showButton={true} />
    </div>
  );
}
