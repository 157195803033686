import React from "react";
import { BlockOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Menu } from "antd";
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Text", "text", <BlockOutlined />),
  getItem("Text Input", "input", <BlockOutlined />),
  getItem("Multiline Text Input", "textarea", <BlockOutlined />),
  getItem("Checkbox", "checkbox", <BlockOutlined />),
  getItem("Drop down", "select", <ArrowDownOutlined />),
];
const ElementToolbar = ({ add }) => {
  // const [collapsed, setCollapsed] = useState(false);
  // const toggleCollapsed = () => {
  //   setCollapsed(!collapsed);
  // };
  return (
    <div className=" shadow-lg">
      <div className="flex flex-row">
        {/* <Button
          // type="primary"
          // onClick={toggleCollapsed}
          style={{
            marginBottom: 16,
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button> */}
        {/* <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold m-2 mb-5">
          Form element Toolbar
        </label> */}
      </div>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        // theme="dark"
        // inlineCollapsed={collapsed}
        onClick={(e) => add(e.key)}
        items={items}
      />
    </div>
  );
};
export default ElementToolbar;
