import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CaseWizard from "../../features/casewizard/CaseWizard";
import firebase from "firebase/app";
import { setRecipients, updateCaseData, setStage } from "../../redux/caseSlice";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function EditCase() {
  usePageTracking();
  let { id } = useParams();
  const dispatch = useDispatch();
  const forceUpdate = React.useState({})[1].bind(null, {});
  const [loading, toggleLoading] = useState(true);

  async function getRecipients() {
    const data = await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("recipients")
      .get();
    if (!data.empty) {
      const arr = [];
      data.forEach((ref) => {
        arr.push({
          ...ref.data(),
          id: ref.id,
        });
      });
      dispatch(setRecipients(arr));
    }
  }

  async function getStages() {
    const data = await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("stages")
      .get();
    if (!data.empty) {
      const arr = [];
      data.forEach((ref) => {
        arr.push({
          ...ref.data(),
          id: ref.id,
        });
      });
      dispatch(setStage(arr));
    }
  }

  async function getCaseData() {
    const data = await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .get();
    if (data.exists) {
      dispatch(updateCaseData(data.data().caseData));
      await getRecipients();
      await getStages();
      forceUpdate();
      toggleLoading(false);
    }
  }

  useEffect(() => {
    getCaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <div className="relative md:ml-64 bg-blueGray-100 p-5"></div>;
  }
  return (
    <div className="relative md:ml-64 bg-blueGray-100 p-5">
      <CaseWizard />
    </div>
  );
}
