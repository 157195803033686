/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import VideoEnforcer from "../../features/VideoEnforcer";
import { confirmAlert } from 'react-confirm-alert'; // Import
import usePageTracking from "../../hooks/usePageTracking";
import 'react-confirm-alert/src/react-confirm-alert.css'

export default function SubmitMedia({
  goBack,
  refereeName = "",
  sumbitMediaFunction,
  caseData = {},
}) {
  usePageTracking();
  const [record, toggleRecord] = React.useState(false);
  const [loading, toggleLoading] = useState(true);
  const [mediaType, setMediaType] = useState("video");

  useEffect(() => {
    setTimeout(() => {
      if (caseData.getAudio) {
        setMediaType("audio");
      } else {
        setMediaType("video");
      }
      toggleLoading(false);
    }, [500]);
  }, []);

  if (loading) {
    return <Loading />;
  }

  function beginRecording(){
   toggleRecord(true);
  }

  const gotToRecorder = () => {
    confirmAlert({
      title: 'Please read',
      message: 'You are about to make a quick recording. Please state your name and confirm you are providing authentic information for this person.',               // Message dialog
      childrenElement: () => <div className="mt-5">You have 20 seconds of video record time to. Try and make it as brief as possible. <br /> Thanks.</div>,       // Custom UI or Component
      buttons: [
        {
          label: 'Record now',
          onClick: () => beginRecording(),
        },
        {
          label: 'Cancel',
          onClick: () => null,
        },
      ],
      overlayClassName: "overlay-custom-class-name" // Custom overlay class name
    })
  };

  if (mediaType === "video" && record) {
    return <VideoEnforcer submitVideoFunction={sumbitMediaFunction} />;
  }

  /* if (mediaType === "video" && record) {
    return <AudioEnforcer submitAudioFunction={sumbitMediaFunction} />;
  } */

  return (
    <div>
      <div
        className="lg:ml-32 lg:mt-12 cursor-pointer"
        onClick={() => goBack()}
      >
        <i className="fas fa-arrow-left text-emerald-500 mr-4 ml-2"></i>
      </div>
      <div className="p-12">
        <h3 className="text-center text-2xl font-semibold mt-5">
          Last part: Video verification!
        </h3>
        <p className="text-center">
          {`We have to be sure this responses was gotten from you, ${refereeName}`}
          .
        </p>
        <div className="flex flex-row justify-center mt-32">
          <i className="fas fa-arrow-down text-emerald-500 self-center text-9xl"></i>
        </div>
        <div className="flex flex-row min-h-screen justify-center mt-12 lg:mt-40">
          <div className="flex flex-col">
            <span>
              Please make sure you grant permission to access your camera for
              this part to be successful.
            </span>
            <button
              className="bg-blue-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
              onClick={() => gotToRecorder()}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
