import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/app";
import CaseDashboard from "../../features/CaseDashboard";
import Loading from "../../components/Loading";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function Case() {
  usePageTracking();
  let { id } = useParams();
  const [caseData, setCaseData] = useState(null);
  const [referees, setReferees] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [stages, setStages] = useState([]);
  const [loading, toggleLoading] = useState(false);

  async function getCaseData() {
    try {
      toggleLoading(true);
      await firebase
        .firestore()
        .collection("cases")
        .doc(`${firebase.auth().currentUser.uid}`)
        .collection("list")
        .doc(id)
        .onSnapshot(async (data) => {
          if (data.exists) {
            setCaseData({
              ...data.data(),
              id: data.id,
            });
            await getReferees();
            await getRecipients();
            await getStages();
            toggleLoading(false);
          }
        });
    } catch (error) {
      toggleLoading(false);
    }
  }

  async function getReferees() {
    await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("referrees")
      .onSnapshot(async (data) => {
        if (!data.empty) {
          const arr = [];
          data.forEach((ref) => {
            arr.push({
              ...ref.data(),
              id: ref.id,
            });
          });
          setReferees(arr);
        }
      });
  }

  async function getRecipients() {
    await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("recipients")
      .onSnapshot(async (data) => {
        if (!data.empty) {
          const arr = [];
          data.forEach((ref) => {
            arr.push({
              ...ref.data(),
              id: ref.id,
            });
          });
          setRecipients(arr);
        }
      });
  }

  async function getStages() {
    await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("stages")
      .orderBy("order")
      .onSnapshot(async (data) => {
        if (!data.empty) {
          const arr = [];
          data.forEach((ref) => {
            arr.push({
              ...ref.data(),
              id: ref.id,
            });
          });
          setStages(arr);
        }
      });
  }

  useEffect(() => {
    getCaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <div className="relative md:ml-64 bg-blueGray-100">
        <Loading />
      </div>
    );
  }

  if (!caseData && !loading) {
    return <div></div>;
  }

  return (
    <CaseDashboard
      data={caseData}
      referees={referees}
      recipients={recipients}
      stages={stages}
    />
  );
}
