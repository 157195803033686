import React, { useState, useEffect } from "react";
import { Steps, theme } from "antd";
import ButtonComp from "../../components/ButtonComp";
import CasedetailsForm from "./CasedetailsForm";
import ReceipentsRecords from "./ReceipentsRecords";
import CaseReview from "./CaseReview";
import CaseCompanyBrand from "./CaseCompanyBrand";
import CaseMetaRequirements from "./CaseMetaRequirements";
import CaseStages from "./CaseStages";
import { useDispatch, useSelector } from "react-redux";
import { setTemplates } from "../../redux/caseSlice";
import firebase from "firebase/app";
import { useNavigate, useParams } from "react-router-dom";
import { CaseStatus, RecipientStatus } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/storage";

const CaseWizard = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { caseData, recipients, deletedRecipients, stages, deletedStages } =
    useSelector((state) => state.case);
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [isValid, toggleIsValid] = useState(false);

  useEffect(() => {
    checkIfFormIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseData, recipients]);

  async function checkIfFormIsValid() {
    const { title, company, description, numberOfReferences } = caseData;
    if (
      !title ||
      !company ||
      !description ||
      !numberOfReferences ||
      parseInt(numberOfReferences) === 0
    ) {
      toggleIsValid(false);
    } else {
      toggleIsValid(true);
    }
  }

  async function saveCase() {
    if (!isValid) {
      toast.error("Form is not complete.");
      return;
    }
    if (recipients.length === 0) {
      toast.error("You need to add at least one recipeient.");
      return;
    }
    if (stages.length === 0) {
      toast.error("You need to add at least one stage for this case.");
      return;
    }
    toast.loading("Saving to database...");
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`cases/images/company/${id}`);
    await imageRef
      .putString(caseData.imageFile.split(",")[1], "base64", {
        contentType: "image/png",
      })
      .then(async () => {
        const imageUrl = await imageRef.getDownloadURL();
        const savedCase = await firebase
          .firestore()
          .collection("cases")
          .doc(`${firebase.auth().currentUser.uid}`)
          .collection("list")
          .add({
            caseData: {
              ...caseData,
              numberOfReferences: parseInt(caseData.numberOfReferences),
              imageUrl: imageUrl,
              refereeSent: 0,
              refereesAwaiting: 0,
            },
            status: CaseStatus.STARTED,
          });
        let db = firebase.firestore();
        let batch = db.batch();
        recipients.forEach((recipient) => {
          let docRef = db
            .collection("cases")
            .doc(`${firebase.auth().currentUser.uid}`)
            .collection("list")
            .doc(savedCase.id)
            .collection("recipients")
            .doc(); //automatically generate unique id
          batch.set(docRef, {
            name: recipient.name,
            email: recipient.email,
            createdAt: new Date().toISOString(),
            status: RecipientStatus.RECIPIENT_CREATED,
            stage: 0,
          });
        });

        let stagedb = firebase.firestore();
        let stagebatch = db.batch();
        stages.forEach((stage, index) => {
          let stageRef = stagedb
            .collection("cases")
            .doc(`${firebase.auth().currentUser.uid}`)
            .collection("list")
            .doc(savedCase.id)
            .collection("stages")
            .doc(); //automatically generate unique id
          stagebatch.set(stageRef, {
            title: stage.title,
            // emailTemplate: stage.emailTemplate,
            templateId: stage.templateId,
            createdAt: new Date().toISOString(),
            order: index,
          });
        });
        await batch.commit();
        await stagebatch.commit();
        toast.success("Saved case to database!");
        navigate(`/cases/${savedCase.id}`);
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error("Unable to save to database.");
      });
  }

  async function removeDeletedRecipeints() {
    if (deletedRecipients.length === 0) {
      return;
    }
    let db = firebase.firestore();
    let batch = db.batch();
    deletedRecipients.forEach((recipientId) => {
      let docRef = db
        .collection("cases")
        .doc(`${firebase.auth().currentUser.uid}`)
        .collection("list")
        .doc(id)
        .collection("recipients")
        .doc(recipientId); //automatically generate unique id
      batch.delete(docRef);
    });
    await batch.commit();
  }

  async function removeDeletedStages() {
    if (deletedStages.length === 0) {
      return;
    }
    let db = firebase.firestore();
    let batch = db.batch();
    deletedStages.forEach((deletedStageId) => {
      let docRef = db
        .collection("cases")
        .doc(`${firebase.auth().currentUser.uid}`)
        .collection("list")
        .doc(id)
        .collection("stages")
        .doc(deletedStageId); //automatically generate unique id
      batch.delete(docRef);
    });
    await batch.commit();
  }

  async function updateCase() {
    if (!isValid) {
      toast.error("Form is not complete.");
      return;
    }
    if (recipients.length === 0) {
      toast.error("You need to add at least one recipeient.");
      return;
    }
    if (stages.length === 0) {
      toast.error("You need to add at least one stage for this case.");
      return;
    }
    if (!caseData.imageFile) {
      toast.error(
        `You need to upload an company logo for ${caseData.company}`
      );
      return;
    }
    toast.loading("Updating record...");
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`cases/images/company/${id}`);
    imageRef
      .putString(caseData.imageFile.split(",")[1], "base64", {
        contentType: "image/png",
      })
      .then(async () => {
        const imageUrl = await imageRef.getDownloadURL();
        firebase
          .firestore()
          .collection("cases")
          .doc(`${firebase.auth().currentUser.uid}`)
          .collection("list")
          .doc(`${id}`)
          .update({
            caseData: {
              ...caseData,
              numberOfReferences: parseInt(caseData.numberOfReferences),
              imageUrl: imageUrl,
            },
            status: 0,
          })
          .then(async () => {
            let db = firebase.firestore();
            stages.forEach((stage, index) => {
              db.collection("cases")
                .doc(`${firebase.auth().currentUser.uid}`)
                .collection("list")
                .doc(id)
                .collection("stages")
                .doc(stage.id)
                .set({
                  title: stage.title,
                  // emailTemplate: stage.emailTemplate,
                  templateId: stage.templateId,
                  createdAt: new Date().toISOString(),
                  order: index,
                });
            });
            await removeDeletedStages();
          })
          .then(async () => {
            let db = firebase.firestore();
            let batch = db.batch();
            recipients
              .filter((recip) => !recip.createdAt)
              .forEach((recipient) => {
                let docRef = db
                  .collection("cases")
                  .doc(`${firebase.auth().currentUser.uid}`)
                  .collection("list")
                  .doc(id)
                  .collection("recipients")
                  .doc(); //automatically generate unique id
                batch.set(docRef, {
                  name: recipient.name,
                  email: recipient.email,
                  createdAt: new Date().toISOString(),
                  // status: 0,
                  // stage: 0,
                });
              });
            await batch.commit();
            await removeDeletedRecipeints();
            toast.success("Saved to database!");
            navigate(`/cases/${id}`);
          })
          .catch((error) => {
            toast.error("Unable to save to database.");
            Sentry.captureException(error);
          });
      });
  }
  const steps = [
    {
      title: "Case details",
      content: <CasedetailsForm />,
    },
    {
      title: "Company brand",
      content: <CaseCompanyBrand />,
    },
    {
      title: "Talent details",
      content: <ReceipentsRecords />,
    },
    {
      title: "Case stages",
      content: <CaseStages />,
    },
    {
      title: "Extra settings",
      content: <CaseMetaRequirements />,
    },
    {
      title: "Preview",
      content: <CaseReview />,
    },
  ];
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  async function getTemplates() {
    const templates = await firebase
      .firestore()
      .collection("templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .get();
    if (!templates.empty) {
      const arr = [];
      templates.forEach((template) => {
        arr.push({
          ...template.data(),
          id: template.id,
        });
      });
      dispatch(setTemplates(arr));
    }
  }

  /* async function getEmailTemplates() {
    const templates = await firebase
      .firestore()
      .collection("email-templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .get();
    if (!templates.empty) {
      const arr = [];
      templates.forEach((template) => {
        arr.push({
          ...template.data(),
          id: template.id,
        });
      });
      dispatch(setEmailTemplates(arr));
    }
  } */

  useEffect(() => {
    getTemplates();
    // getEmailTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDone = async () => {
    if (id) {
      await updateCase();
    } else {
      await saveCase();
    }
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    color: token.colorTextTertiary,
    backgroundColor: token.App,
    borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    height: "auto",
  };
  return (
    <>
      <ToastContainer />
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <ButtonComp
            title="Next"
            onClick={next}
            backgroundColor="bg-green-500"
          />
        )}
        {current === steps.length - 1 && (
          <ButtonComp title="Done" onClick={onDone} />
        )}
        {current > 0 && <ButtonComp title="Previous" onClick={prev} />}
      </div>
    </>
  );
};
export default CaseWizard;
