import React from "react";
import { Button, Empty, Tooltip } from "antd";
import { RefereeStatus } from "../../utils/enums";

export default function RefereeResponses({
  referees = [],
  resendRequest,
  gotoAnswers,
  stages=[],
  resetAnswersAndRequestResponse,
}) {
  const RowItem = (data) => {
        return (
      <tr key={JSON.stringify(data)}>
        <th className="border-t-0 cursor-pointer px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
          <Tooltip title={`${data.refereeEmail}`}>
            <span> {data.refereeName}</span>
          </Tooltip>
        </th>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {data.talentName}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <span>{stages[data.stage]?.title}</span>
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {new Date(data.createdAt).toDateString()}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {data.status === RefereeStatus.RESPONSE_RECEIVED ? (
            <span>
              Response received{" "}
              <i className="fas fa-check text-emerald-500 mr-4 ml-2"></i>
            </span>
          ) : (
            <span>
              Response not received{" "}
              <i className="fas fa-uncheck text-emerald-500 mr-4 ml-2"></i>
            </span>
          )}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {data.status === RefereeStatus.RESPONSE_RECEIVED
            ? new Date(data.responseDate).toDateString()
            : "No response yet"}
        </td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
          {data.status === RefereeStatus.RESPONSE_REQUESTED && (
            <Button
              onClick={() => {
                const refereeArray = [
                  {
                    email: data.refereeEmail,
                    name: data.refereeName,
                    refereeId: data.id,
                    caseId: data.caseId,
                  },
                ];
                resendRequest && resendRequest(data.talentName, refereeArray);
              }}
            >
              Remind referee for response
            </Button>
          )}
          {data.status === RefereeStatus.RESPONSE_RECEIVED && (
            <div>
              <Button
                onClick={() => {
                  gotoAnswers && gotoAnswers(data.refereeName);
                }}
              >
                View response
              </Button>
              <Button
                onClick={() => {
                  resetAnswersAndRequestResponse &&
                    resetAnswersAndRequestResponse(data);
                }}
                style={{ marginLeft: 5 }}
              >
                Clear answers and request again
              </Button>
            </div>
          )}
        </td>
      </tr>
    );
  };
  return (
    <div className="w-full xl:w-fit mb-12 xl:mb-0 px-4">
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Referee responses for this case
              </h3>
            </div>
            {/* <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={() => sendRemindersToTalents && sendRemindersToTalents()}
              >
                Send reminders to all referees
              </button>
            </div> */}
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Referee name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Talent name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Stage
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date sent
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Response date
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            {referees.length > 0 && (
              <tbody>
                {referees.map((referee) => (
                  <RowItem {...referee} key={`${JSON.stringify(referee)}`} />
                ))}
              </tbody>
            )}
          </table>
        </div>
        {referees.length === 0 && (
          <div className="flex flex-row justify-center py-8">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
}
