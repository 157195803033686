import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import firebase from "firebase/app";
import FormViwer from "../../components/FormViewer";
import SubmitMedia from "./submitMedia";
import Loading from "../../components/Loading";
import Lottie from "lottie-react";
import * as Sentry from "@sentry/react";
import api from "../../api";
import alreadyFilledAnimation from "../../assets/animations/alreadyFilled.json";
import { RefereeStatus, RecipientStatus } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/storage";

export default function RefereeQuestionnaire() {
  usePageTracking();
  const navigate = useNavigate();
  const {
    refereeName,
    talentName,
    caseId,
    userId,
    templateId,
    stage,
    recipientId,
  } = useParams();
  const [template, setTemplate] = useState(null);
  const [caseData, setCaseData] = useState(null);
  const [page, setPage] = useState(0);
  const [results, setResults] = useState(null);
  const [alreadyFilled, setAlreadyFilled] = useState(false);
  const [talentReferees, setTalentReferees] = useState([]);

  async function getTemplateForm() {
    const templateForm = await firebase
      .firestore()
      .collection("templates")
      .doc(userId)
      .collection("list")
      .doc(templateId)
      .get();
    if (templateForm.exists) {
      setTemplate({
        ...templateForm.data(),
        id: templateForm.id,
      });
    }
  }

  async function getTalentReferees() {
    try {
      const referees = await firebase
        .firestore()
        .collection("cases")
        .doc(userId)
        .collection("list")
        .doc(caseId)
        .collection("referrees")
        .where("talentName", "==", `${talentName.replaceAll("+", " ")}`)
        .get();
      if (!referees.empty) {
        const arr = [];
        referees.forEach((referee) => {
          arr.push({
            ...referee.data(),
            id: referee.id,
          });
        });
        setTalentReferees(arr);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function checkIfSubmitted() {
    try {
      const referee = await firebase
        .firestore()
        .collection("cases")
        .doc(userId)
        .collection("list")
        .doc(caseId)
        .collection("referrees")
        .where("refereeName", "==", refereeName)
        .get();
      if (!referee.empty) {
        if (referee.docs[0].data().status === RefereeStatus.RESPONSE_RECEIVED) {
          setAlreadyFilled(true);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  const getCaseData = async () => {
    try {
      const caseObject = await firebase
        .firestore()
        .collection("cases")
        .doc(`${userId}`)
        .collection("list")
        .doc(caseId)
        .get();
      if (caseObject.exists) {
        setCaseData({
          ...caseObject.data(),
          id: caseObject.id,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  async function updateTalentStatus() {
    try {
      if (recipientId) {
        const talentRefereesWithoutSubmissions = talentReferees.filter(
          (tf) => tf.status !== RefereeStatus.RESPONSE_RECEIVED
        );
        if (parseInt(stage) === -1) {
          // if there is just one referee left without a submission and its the last stage i.e stage === -1.
          // Then mark user as completed
          // if not then mark the user as referee has submitted
          await firebase
            .firestore()
            .collection("cases")
            .doc(userId)
            .collection("list")
            .doc(caseId)
            .collection("recipients")
            .doc(recipientId)
            .update({
              status:
                talentRefereesWithoutSubmissions.length === 1
                  ? RecipientStatus.COMPLETE
                  : RecipientStatus.USER_REFEREE_RESPONDED,
            });
          return;
        } else {
          // if its not the final stage:
          // if its just one referee left wihtout a response then move user to the next stage, else just mark user as referee submitted
          await firebase
            .firestore()
            .collection("cases")
            .doc(userId)
            .collection("list")
            .doc(caseId)
            .collection("recipients")
            .doc(recipientId)
            .update({
              status:
                talentRefereesWithoutSubmissions.length === 1
                  ? RecipientStatus.RECIPIENT_CREATED
                  : RecipientStatus.USER_REFEREE_RESPONDED,
              stage: parseInt(stage) + 1,
            });
        }
      } else {
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function informOwnerOfSubmission() {
    if (caseData.caseData?.emailInform) {
      const userDetails = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get();
      if (userDetails.exists) {
        if (caseData) {
          const postBody = {
            refereeName,
            talentName,
            caseName: caseData.caseData.title,
            caseId,
            userEmail: userDetails.data().email,
            image: caseData.caseData.imageUrl,
            color: caseData.caseData.color,
          };
          await api.post("/referee/informownerrefereesubmission", postBody);
        }
      }
    }
  }

  useEffect(() => {
    getTalentReferees();
    checkIfSubmitted();
    getCaseData();
    getTemplateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* const addNotification = async () => {
    await firebase
      .firestore()
      .collection("notifications")
      .doc(userId)
      .collection("cases")
      .doc(caseId)
      .collection("list")
      .add({
        title: `Referee submission`,
        details: `Referee for ${talentName}, ${refereeName} has submitted responses.`,
        isSeen: false,
        createdAt: new Date().toISOString(),
      });
  }; */

  async function saveResults(videoUrl, formresults) {
    try {
      const referee = talentReferees.find(
        (referee) => referee.refereeName === refereeName.replaceAll("+", " ")
      );
      if (referee) {
        await storeAnswers(referee.id, formresults, videoUrl);
        await updateReferenceSubmittedStatus(referee.id);
        await updateTalentStatus();
        // await addNotification();
        await informOwnerOfSubmission();
        toast.success(
          "Thank you! You have successfully submitted, you can close the tab now."
        );
        navigate("/reference/questionnaire/complete");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function submitForm(videoFile) {
    try {
      if (!videoFile) {
        await saveResults(null);
      } else {
        const storageRef = firebase.storage().ref();
        const caseVideoRef = storageRef.child(
          `cases/videos/${caseId}/${userId}/${refereeName}`
        );
        caseVideoRef.putString(videoFile, "data_url").then(async () => {
          const videoUrl = await caseVideoRef.getDownloadURL();
          await saveResults(videoUrl, results);
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function analyseResults(results) {
    try {
      const postBody = {
        userResponses: results,
      };
      const result = await api.post("/ai/analyseTextResponses", postBody);
      return result.data;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function gotoSumitMedia(formresults = []) {
    try {
      const isNullValueSeen = formresults.some(
        (res) => res.value === null || res.value === ""
      );
      if (isNullValueSeen) {
        toast.error(
          "Please answer all questions before going to the next stage. Thanks!"
        );
        return;
      }
      const analysedFormResults = await analyseResults(formresults);
      setResults(analysedFormResults);
      if (caseData.caseData.getVideo) {
        setResults(analysedFormResults);
        setPage(1);
      } else {
        toast.loading("Submitting your responses. Please wait");
        setResults(analysedFormResults);
        await saveResults(undefined, analysedFormResults);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function storeAnswers(id, formresults, mediaUrl) {
    if (mediaUrl) {
      await firebase
        .firestore()
        .collection("cases")
        .doc(userId)
        .collection("list")
        .doc(caseId)
        .collection("referrees")
        .doc(id)
        .update({
          responses: formresults,
          mediaUrl,
        });
    } else {
      await firebase
        .firestore()
        .collection("cases")
        .doc(userId)
        .collection("list")
        .doc(caseId)
        .collection("referrees")
        .doc(id)
        .update({
          responses: formresults,
        });
    }
  }

  async function updateReferenceSubmittedStatus(id) {
    await firebase
      .firestore()
      .collection("cases")
      .doc(userId)
      .collection("list")
      .doc(caseId)
      .collection("referrees")
      .doc(id)
      .update({
        status: RefereeStatus.RESPONSE_RECEIVED,
        responseDate: new Date().toUTCString(),
      });
  }

  const blobToBase64 = (url) => {
    return new Promise(async (resolve, _) => {
      // do a request to the blob uri
      const response = await fetch(url);

      // response has a method called .blob() to get the blob file
      const blob = await response.blob();

      // instantiate a file reader
      const fileReader = new FileReader();

      // read the file
      fileReader.readAsDataURL(blob);

      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  async function onSubmitMedia(blobUrl) {
    try {
      const file = await blobToBase64(blobUrl);
      submitForm(file);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  if (alreadyFilled) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center justify-center">
          <Lottie
            animationData={alreadyFilledAnimation}
            style={{ width: 300, height: 300 }}
            loop={true}
          />
          <span className="text-sm text-navy-700 dark:text-white font-bold text-center">
            You have already filled this form.
          </span>
          <small>Click below to request a reset.</small>
          <button
            // onClick={handleCancel}
            className="bg-green-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 mt-5"
          >
            <span>Send reset request</span>
          </button>
        </div>
      </div>
    );
  }

  if (template && page === 0) {
    return (
      <div className="p-10">
        <ToastContainer />
        <Link
          to="/"
          class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            class="w-8 h-8 mr-2"
            src="https://res.cloudinary.com/makemeup/image/upload/v1701291581/logo_qg5ehf.png"
            alt="botreflogo"
          />
          Botref
        </Link>
        <div className="flex flex-row min-h-screen justify-center">
          <FormViwer
            title={`Hello, you are required to answer a couple of questions about ${talentName.replaceAll(
              "+",
              " "
            )}.`}
            elementsArray={template.elements}
            submitQuestionnaire={gotoSumitMedia}
          />
        </div>
      </div>
    );
  }

  if (template && page === 1) {
    return (
      <div>
        <ToastContainer />
        <SubmitMedia
          goBack={() => setPage(0)}
          refereeName={refereeName}
          sumbitMediaFunction={onSubmitMedia}
          caseData={caseData.caseData}
        />
      </div>
    );
  }

  return <Loading />;
}
