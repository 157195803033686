import React, { useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import usePageTracking from "../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/database";

export default function ForgotPassword() {
  usePageTracking();
  const [email, setEmail] = useState(null);

  async function doReset() {
    if (!email) {
      toast.success("Please enter a valid email.");
      return;
    }
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(`Instructions have been sent to ${email}.`);
        setEmail(null);
      })
      .catch((error) => {
        toast.error(error);
        Sentry.captureException(error);
      });
  }
  return (
    <div>
      <ToastContainer />
      <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="#"
            class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              class="w-14 h-14 mr-2"
              src="https://res.cloudinary.com/makemeup/image/upload/v1701291581/logo_qg5ehf.png"
              alt="botreflogo"
            />
            Botref
          </Link>
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset your password
              </h1>
              <div class="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    id="email"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="your@email.com"
                    required=""
                  />
                </div>
                <button
                  onClick={() => doReset()}
                  className="w-full bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ml-auto"
                >
                  Send request
                </button>
                <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  Wanna try your luck again?{" "}
                  <Link
                    to="/login"
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Go back to login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
