import React, { useState, useEffect } from "react";
import { Checkbox, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateCaseData } from "../../redux/caseSlice";
import { useUserContext } from "../../contexts/UserContext";

export default function CaseMetaRequirements() {
  const caseData = useSelector((state) => state.case.caseData);
  const dispatch = useDispatch();
  const {
    features: { isAudio, isVideo, isEmail },
  } = useUserContext();
  const [getAudio, toggleGetAudio] = useState(caseData?.getAudio || false);
  const [getVideo, toggleGetVideo] = useState(caseData?.getVideo || false);
  const [emailInform, setEmailInform] = useState(
    caseData?.emailInform || false
  );

  useEffect(() => {
    dispatch(
      updateCaseData({
        getAudio,
        getVideo,
        emailInform,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAudio, getVideo, emailInform]);
  return (
    <div class="lg:w-1/3 m-auto mt-20">
      <p className="font-bold mb-2 text-sm text-center">
        Specify what extra features you want for this case.
      </p>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-6">
          <Checkbox
            checked={emailInform}
            onChange={() => {
              if (isEmail) {
                setEmailInform(!emailInform);
              } else {
                message.error(
                  "This feature is not available for your current plan"
                );
              }
            }}
          >
            Do you want to receive email notifications on each case activity?
          </Checkbox>
        </div>
        <div class="mb-4">
          <Checkbox
            checked={getAudio}
            onChange={(e) => {
              if (isAudio) {
                toggleGetAudio(!getAudio);
                toggleGetVideo(false);
              } else {
                message.error(
                  "This feature is not available for your current plan"
                );
              }
            }}
          >
            Do you want to force referees to submit audio evidence before
            submitting?
          </Checkbox>
        </div>
        <div class="mb-6">
          <Checkbox
            checked={getVideo}
            onChange={() => {
              if (isVideo) {
                toggleGetAudio(false);
                toggleGetVideo(!getVideo);
              } else {
                message.error(
                  "This feature is not available for your current plan"
                );
              }
            }}
          >
            Do you want to force referees to submit video evidence before
            submitting?
          </Checkbox>
        </div>
      </div>
    </div>
  );
}
