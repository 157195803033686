import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import {
  LogoutOutlined,
  ProfileOutlined,
  DollarOutlined,
  FormOutlined,
  OrderedListOutlined,
  // MailOutlined,
} from "@ant-design/icons";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const isSelected = (linkText) => {
    const path = `${pathname}`.replace("/", "");
    return `${linkText}`.toLowerCase() === path
      ? "text-pink-500 hover:text-pink-600"
      : "text-blueGray-700 hover:text-blueGray-500";
  };
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 lg:py-4 py-1 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm font-bold p-4 px-0"
            to="/"
          >
            Botref
          </Link>
          {/* User */}
          {/* <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul> */}
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded border-b-2 " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Botref
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {/* <li className="items-center">
                <Link
                  className={`${isSelected(
                    "dashboard"
                  )} text-xs uppercase py-3 font-bold block`}
                  to="/dashboard"
                >
                  <i className="fas fa-tv opacity-75 mr-2 text-sm"></i>{" "}
                  Dashboard
                </Link>
              </li> */}

              <li className="items-center">
                <Link
                  className={`${isSelected(
                    "templates"
                  )} text-xs uppercase py-3 font-bold block`}
                  to="/templates"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <FormOutlined />
                  <span className="mt-8 ml-3">Questionnaire Templates</span>
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={`${isSelected(
                    "email-templates"
                  )} text-xs uppercase py-3 font-bold block`}
                  to="/email-templates"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <MailOutlined />
                  <span className="mt-8 ml-3">Email Templates</span>
                </Link>
              </li> */}

              <li className="items-center">
                <Link
                  className={`${isSelected(
                    "cases"
                  )} text-xs uppercase py-3 font-bold block`}
                  to="/cases"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <OrderedListOutlined />
                  <span className="mt-8 ml-3">Cases</span>
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Account
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="inline-flex mt-1">
                <Link
                  className={`${isSelected(
                    "settings"
                  )} text-xs uppercase py-3 font-bold block`}
                  to="/settings"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <ProfileOutlined />
                  <span className="mt-8 ml-3">Profile</span>
                </Link>
              </li>

              <li className="inline-flex mt-1">
                <Link
                  className={`${isSelected(
                    "billings"
                  )} text-xs uppercase py-3 font-bold block mb-2`}
                  to="/billings"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <DollarOutlined />
                  <span className="mt-8 ml-3">Billing</span>
                </Link>
              </li>

              <li
                className="inline-flex mt-2"
                onClick={async () => {
                  await firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      navigate("/");
                    });
                }}
              >
                <div
                  className="text-blueGray-700 hover:text-blueGray-500  text-sm block mb-4 no-underline font-semibold cursor-pointer"
                  to="#"
                >
                  <LogoutOutlined />
                  <span className="mt-8 ml-3">Log out</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
