import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCaseData } from "../../redux/caseSlice";
import FileBase64 from "react-file-base64";
import { SketchPicker } from "react-color";

export default function CaseCompanyBrand() {
  const dispatch = useDispatch();
  const { imageFile, color } = useSelector((state) => state.case.caseData);
  const [setcolor, setSetColor] = useState(color || "#38D1C5");
  function getFiles(file) {
    dispatch(
      updateCaseData({
        imageFile: file.base64,
      })
    );
  }
  const handleChangeComplete = (color) => {
    setSetColor(color?.hex);
    dispatch(
      updateCaseData({
        color: color.hex,
      })
    );
  };
  return (
    <div className="flex flex-col pb-10">
      <p className="font-bold mb-2 text-sm lg:text-center pt-12">
        Provide basic branding like images and company brand color to be used
        when reaching out to talent.
      </p>
      <div className="flex h-auto w-full lg:items-center justify-center mt-10 lg:pl-0 pl:5">
        <div className="lg:grid lg:grid-cols-2 flex flex-col">
          <div className="flex flex-col">
            <span className="font-bold text-sm mb-1">
              Select company image:
            </span>
            <FileBase64
              accept="image/*"
              multiple={false}
              onDone={(e) => getFiles(e)}
            />
            <p className="mt-5 mb-2 font-bold">Select brand color:</p>
            <SketchPicker color={setcolor} onChange={handleChangeComplete} />
          </div>
          <div className="lg:border-l-2 lg:pl-10 lg:mt-0 mt-10">
            {imageFile && (
              <img
                src={imageFile}
                alt="company_image"
                className="w-52 h-fit mb-5"
              />
            )}
            <p className="font-bold text-2xl lg:mb-5" style={{ color: color }}>
              Sample text
            </p>
            <button
              className="w-full text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ml-auto"
              style={{ backgroundColor: setcolor }}
            >
              Sample button
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
