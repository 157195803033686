import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import TalentList from "./casewizard/TalentList";
import Casefooter from "./casewizard/CaseFooter";
import RefereeResponses from "./casewizard/RefereeResponses";
// import RefereeResponseCard from "./casewizard/cardInfos/RefereeResponseCard";
import RefereeCountCard from "./casewizard/cardInfos/RefereeCountCard";
import TalentCountCard from "./casewizard/cardInfos/TalentCountCrad";
// import AwaitingResponseCard from "./casewizard/cardInfos/AwaitingResponseCard";
// import PerformanceCard from "./casewizard/cardInfos/PerformanceCard";
import CaseDashboardHeader from "./casewizard/CaseDashboardHeader";
import { RefereeStatus, RecipientStatus } from "../utils/enums";
import firebase from "firebase/app";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import "firebase/auth";
import "react-toastify/dist/ReactToastify.css";

export default function CaseDashboard({
  data = {},
  referees = [],
  recipients = [],
  stages = [],
}) {
  // const awaitingLength = referees.filter((ref) => ref.status !== RefereeStatus.RESPONSE_RECEIVED).length;
  // const percentCompletion = 100 - ((awaitingLength * stages.length)/ (referees.length * stages.length)) * 100;
  const navigate = useNavigate();
  const title = data.caseData.title;
  const company = data.caseData.company;
  const maxReferreNumber = data.caseData.numberOfReferences;
  const caseColor = data.caseData.color || "#60a5fa";

  function editCase() {
    navigate(`/cases/edit/${data.id}`);
  }

  function gotoAnswers(refereeName) {
    navigate(`/cases/answers/${data.id}/${refereeName}`);
  }

  const requestDetailsFromReferees = async (talentName, refereeArray = []) => {
    try {
      toast.loading("Sending reminder. Please wait");
      const recipient = recipients.find((recip) => recip.name === talentName);
      const recipientStage = stages.find(
        (stage) => stage.order === recipient.stage
      );
      const postBody = {
        referees: refereeArray,
        companyName: company,
        talentName: talentName,
        caseId: data.id,
        userId: firebase.auth().currentUser.uid,
        templateId: data.caseData.caseTemplate,
        image: data.caseData?.imageUrl,
        color: data?.caseData?.color,
        formTemplateId: recipientStage.templateId,
        stage: recipient.stage,
      };
      await api.post("/request/referee", postBody);
      toast.success(`Request resent to ${refereeArray[0].name}`);
    } catch (error) {
      toast.error(`Unable to send request`);
      Sentry.captureException(error);
    }
  };

  async function sendEmailRequest(name, email, caseData, recipientId, stage) {
    try {
      const requestBody = {
        name,
        email,
        caseId: caseData.id,
        userId: firebase.auth().currentUser.uid,
        companyName: caseData?.caseData?.company,
        recipientId: recipientId,
        image: data?.caseData?.imageUrl,
        color: data?.caseData?.color,
        order: stage,
      };
      await api.post("/request/talent", requestBody);
    } catch (error) {
      toast.error(
        "Unable to send request. Please try again or contact support."
      );
      Sentry.captureException(error);
    }
  }

  async function addNotification(recipient) {
    await firebase
      .firestore()
      .collection("notifications")
      .doc(firebase.auth().currentUser.uid)
      .collection("cases")
      .doc(data.id)
      .collection("list")
      .add({
        title: `Sent reminder to talent`,
        details: `Sent reminder to ${recipient[0].name}`,
        isSeen: false,
        createdAt: new Date().toISOString(),
      });
  }

  async function sendRefereeRequestToSingleTalent(recipient) {
    try {
      toast.loading("Sending request, please wait.");
      await sendRefereeRequestToTalent(recipient)
        .then(async () => {
          await addNotification(recipient).then(() => {
            toast.success(`Reminder sent to ${recipient[0].name}`);
          });
        })
        .catch((error) => {
          toast.error("Unable to send reminder");
          Sentry.captureException(error);
        });
    } catch (error) {
      toast.error("Unable to send reminder");
      Sentry.captureException(error);
    }
  }

  async function sendRefereeRequestToTalent(recipientsArray = recipients) {
    try {
      toast.info('Sending reminder(s)');
      for (let i = 0; i < recipientsArray.length; i++) {
        const recipient = recipientsArray[i];
        await sendEmailRequest(
          recipient.name,
          recipient.email,
          data,
          recipient.id,
          recipient.stage
        );
      }
      let db = firebase.firestore();
      let batch = db.batch();
      recipientsArray.forEach((recipient) => {
        let docRef = db
          .collection("cases")
          .doc(`${firebase.auth().currentUser.uid}`)
          .collection("list")
          .doc(data.id)
          .collection("recipients")
          .doc(recipient.id); //automatically generate unique id
        batch.update(docRef, {
          status: RecipientStatus.USER_REQUESTED_FOR_REFEREE_DATA,
        });
      });
      await batch.commit().then(() => {
        toast.success("Sent successfully");
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function resetAnswersAndRequestResponse(referee) {
    await firebase
      .firestore()
      .collection("cases")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .doc(data.id)
      .collection("referrees")
      .doc(referee.id)
      .update({
        status: RefereeStatus.RESPONSE_REQUESTED,
        responses: firebase.firestore.FieldValue.delete(),
      })
      .then(async () => {
        const refereeArray = [
          {
            email: referee.refereeEmail,
            name: referee.refereeName,
            refereeId: referee.id,
            caseId: data.caseId,
          },
        ];
        await requestDetailsFromReferees(referee.talentName, refereeArray);
        toast.success(
          `Response cleared and a fresh email have been sent to ${referee.refereeName}`
        );
        // window.location.reload();
      });
  }

  async function mainAction() {
    switch (data.status) {
      case 0:
        await sendRefereeRequestToTalent();
        break;
      case 1:
        return "Referee request to talent, awaiting responses.";
      default:
      // console.log(`Sorry, we are out of ${statusCode}.`);
    }
  }

  async function completeTalentCase(talent) {
    await firebase
      .firestore()
      .collection("cases")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .doc(data.id)
      .collection("recipients")
      .doc(talent.id)
      .update({
        status: 4,
      })
      .then(() => toast.success(`Case completed for ${talent.name}`))
      .catch((error) => {
        toast.error("Unable to close case")
        Sentry.captureException(error);
      });
  }
  return (
    <div>
      <ToastContainer />
      <div className="relative md:ml-64">
        <CaseDashboardHeader
          title={title}
          company={company}
          editCase={editCase}
          mainAction={mainAction}
          data={data}
          image={data.caseData.imageUrl}
          backgroundcolor={caseColor}
        />
        <div
          className={`relative lg:pt-12 pt-12 pb-32`}
          style={{ backgroundColor: caseColor }}
        >
          <div className="px-0 md:px-10 mx-auto w-full">
            <div>
              <div className="flex flex-wrap">
                {/* <RefereeResponseCard referees={referees} /> */}
                <RefereeCountCard refereeCount={referees.length} />
                <TalentCountCard talentCount={recipients.length} />
                {/* <AwaitingResponseCard
                  awaitingList={referees.length - awaitingLength}
                /> */}
                {/* <PerformanceCard performance={percentCompletion} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <TalentList
              recipients={recipients}
              referees={referees}
              maxReferreNumber={maxReferreNumber}
              sendRecipientReminder={sendRefereeRequestToSingleTalent}
              sendCompleteFunction={completeTalentCase}
              sendReminders={sendRefereeRequestToTalent}
              stages={stages}
            />
          </div>
          <div className="flex flex-wrap mt-4">
            <RefereeResponses
              referees={referees}
              stages={stages}
              resendRequest={requestDetailsFromReferees}
              gotoAnswers={gotoAnswers}
              resetAnswersAndRequestResponse={resetAnswersAndRequestResponse}
            />
          </div>
          <Casefooter />
        </div>
      </div>
    </div>
  );
}
