import * as React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NewHome from "./pages/NewHome";
import Cases from "./pages/cases/Cases";
import NewCase from "./pages/cases/New";
import Templates from "./pages/templates/Templates";
import NewTemplate from "./pages/templates/New";
import ForgotPassword from "./pages/ForgottenPassword";
import ProtectedRoute from "./components/ProtectedRoute";
import Landing from "./pages/Landing";
import EditTemplate from "./pages/templates/Edit";
import Case from "./pages/cases/Case";
import EditCase from "./pages/cases/Edit";
import SubmiReference from "./pages/references/submit";
import RefereeQuestionnaire from "./pages/references/questionnaire";
import CompleteQuestionnaire from "./pages/references/complete";
import CaseAnswers from "./pages/references/answers";
import Settings from "./pages/Settings";
import ReadyMadeTemplates from "./pages/templates/ReadyMadeTemplates";
import ClaimTemplate from "./pages/templates/ClaimTemplate";
import NewAdminTemplate from "./pages/templates/NewAdmin";
import PricingPage from "./pages/billing/PricingPage";
import Billings from "./pages/billing/Bllings";
import Notifications from "./pages/Notifications";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/Privacy";
import EmailTemplates from "./pages/email-templates/email-templates";
import NewEmailTemplate from "./pages/email-templates/New";
import EditEmailTemplate from "./pages/email-templates/Edit";
import PaymentForm from "./features/billing/PaymentForm";
// eslint-disable-next-line no-unused-vars
import { app } from "./firebase-config";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-153072332-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  Sentry.init({
    dsn: "https://c1dfa302c5ebf86d94ed1c374db4e7fc@o4506447567781888.ingest.sentry.io/4506447609987072",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "*",
      element: <Landing />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/password-reset",
      element: <ForgotPassword />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/dashboard",
      element: <ProtectedRoute component={NewHome} />,
    },
    {
      path: "/cases",
      element: <ProtectedRoute component={Cases} />,
    },
    {
      path: "/cases/new",
      element: <ProtectedRoute component={NewCase} />,
    },
    {
      path: "/cases/:id",
      element: <ProtectedRoute component={Case} />,
    },
    {
      path: "/cases/edit/:id",
      element: <ProtectedRoute component={EditCase} />,
    },
    {
      path: "/templates/new",
      element: <ProtectedRoute component={NewTemplate} />,
    },
    {
      path: "/templates/admin/new",
      element: <ProtectedRoute component={NewAdminTemplate} />,
    },
    {
      path: "/templates/edit/:id",
      element: <ProtectedRoute component={EditTemplate} />,
    },
    {
      path: "/templates",
      element: <ProtectedRoute component={Templates} />,
    },
    {
      path: "/email-templates",
      element: <ProtectedRoute component={EmailTemplates} />,
    },
    {
      path: "/email-templates/new",
      element: <ProtectedRoute component={NewEmailTemplate} />,
    },
    {
      path: "/email-templates/edit/:id",
      element: <ProtectedRoute component={EditEmailTemplate} />,
    },
    {
      path: "/reference/submit/:userId/:caseId/:userFullName/:recipientId/:formTemplateId",
      element: <SubmiReference />,
    },
    {
      path: "/reference/questionnaire/:userId/:caseId/:talentName/:refereeName/:templateId/:stage/:recipientId",
      element: <RefereeQuestionnaire />,
    },
    {
      path: "/reference/questionnaire/complete",
      element: <CompleteQuestionnaire />,
    },
    {
      path: "/terms-of-service",
      element: <TermsAndConditions />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/cases/answers/:id/:refereeName",
      element: <ProtectedRoute component={CaseAnswers} />,
    },
    {
      path: "/settings",
      element: <ProtectedRoute component={Settings} />,
    },
    {
      path: "/templates/readymade",
      element: <ProtectedRoute component={ReadyMadeTemplates} />,
    },
    {
      path: "/templates/usetemplate/:id",
      element: <ProtectedRoute component={ClaimTemplate} />,
    },
    {
      path: "billings/pricing",
      element: <ProtectedRoute component={PricingPage} />,
    },
    {
      path: "/billings/checkout/:plan",
      element: <ProtectedRoute component={PaymentForm} />,
    },
    {
      path: "/billings",
      element: <ProtectedRoute component={Billings} />,
    },
    {
      path: "/:caseId/notifications",
      element: <ProtectedRoute component={Notifications} />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
