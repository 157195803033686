import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useParams, useNavigate } from "react-router-dom";
import FormBuilder from "../../features/FormBuilder";
import { message } from "antd";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import { useUserContext } from "../../contexts/UserContext";
import * as Sentry from "@sentry/react";
import { ToastContainer, toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowsDimensions";
import ScreenSizeBlocker from "../../components/ScreensizeBlocker";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function ClaimTemplate() {
  usePageTracking();
  let { id } = useParams();
  const navigate = useNavigate();
  const [width,] = useWindowSize();
  const { features, loading: contextLoading } = useUserContext();
  const [template, setTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedTemplate, setUpdatedTemplate] = useState(null);
  const [templateCustomName, setTemplateCustomName] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [, toggleLoading] = useState(contextLoading);

  async function getTemplates() {
    try {
      toggleLoading(true);
      const templates = await firebase
        .firestore()
        .collection("templates")
        .doc(firebase.auth().currentUser.uid)
        .collection("list")
        .get();
      if (!templates.empty) {
        const arr = [];
        templates.forEach((template) => {
          arr.push({
            ...template.data(),
            id: template.id,
          });
        });
        setTemplates(arr);
        toggleLoading(false);
      }
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
    }
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await save(updatedTemplate, templateCustomName);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const saveDataAndOpenModal = async (objs, templateName) => {
    if (features.templateNumber === -1) {
      setUpdatedTemplate(objs);
      showModal();
    } else {
      if (
        features?.templateNumber &&
        parseInt(templates.length) + 1 > features?.templateNumber
      ) {
        message.error(
          `You cannot create more than ${templates.length} template(s) with your current plan.`
        );
      } else {
        setUpdatedTemplate(objs);
        showModal();
      }
    }
  };

  const save = (objs, templateName) => {
    firebase
      .firestore()
      .collection("templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .add({
        title: templateName,
        elements: objs,
      })
      .then(() => {
        message.success("Template saved");
        navigate(`/templates`);
      })
      .catch((error) => {
        toast.error('Unable to save template');
        Sentry.captureException(error);
      });
  };

  async function getTemplate() {
    const template = await firebase
      .firestore()
      .collection("templates")
      .doc("readymade")
      .collection("list")
      .doc(id)
      .get();
    if (template.exists) {
      setTemplate(template.data());
      setTemplateCustomName(template.data().title);
    }
  }

  useEffect(() => {
    getTemplates();
    getTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!template) {
    return (
      <div className="relative md:ml-64 bg-blueGray-100">
        <Loading />
      </div>
    );
  }

  if (width <= 600) {
    return (
      <ScreenSizeBlocker />
    );
  }

  return (
    <div className="relative md:ml-64 bg-blueGray-100">
       <ToastContainer />
      {isModalOpen && (
        <Modal
          handleCancel={handleCancel}
          handleOk={handleOk}
          showModal={isModalOpen}
          title="Save template"
        >
          <div>
            <input
              className="border-2 rounded-md px-5 mx-1 h-10"
              placeholder="Enter template name"
              value={templateCustomName}
              onChange={(e) => setTemplateCustomName(e.target.value)}
            />
            <div className="flex flex-row mt-5 justify-evenly">
              <button
                onClick={handleCancel}
                className="bg-red-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={handleOk}
                className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      <FormBuilder
        save={saveDataAndOpenModal}
        savedElements={template.elements}
        formName={template.title}
      />
    </div>
  );
}
