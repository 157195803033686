import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";

const AIStatistics = ({ responses = [] }) => {
  const negative = responses.filter((response) => response.analysis.neu > 0 || response.analysis.neg).length;
  const positive = responses.filter((response) => response.analysis.pos > 0).length;
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={true}>
          <Statistic
            title="Positive"
            value={((responses.length - positive)/responses.length) * 100}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            prefix={<ArrowUpOutlined />}
            suffix="%"
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={true}>
          <Statistic
            title="Negative"
            value={((responses.length - negative)/responses.length) * 100}
            precision={2}
            valueStyle={{ color: "#cf1322" }}
            prefix={<ArrowDownOutlined />}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default AIStatistics;
