import React, { useState, useEffect } from "react";
import Input from "../../components/Input";
import { InputNumber } from "antd";
import { Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateCaseData } from "../../redux/caseSlice";
import { useUserContext } from "../../contexts/UserContext";

export default function CasedetailsForm() {
  const dispatch = useDispatch();
  const {
    features: { refereeNumber },
  } = useUserContext();
  const caseData = useSelector((state) => state.case.caseData);
  const [title, setTitle] = useState(caseData.title);
  const [description, setDescription] = useState(caseData.description);
  const [company, setCompany] = useState(caseData.company);
  const [numberOfReferences, setNumberOfReferences] = useState(
    parseInt(caseData.numberOfReferences) || 1
  );

  useEffect(() => {
    dispatch(
      updateCaseData({
        title,
        company,
        description,
        numberOfReferences,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, company, description, numberOfReferences]);

  return (
    <div className="flex flex-col lg:w-full items-center justify-center py-12">
      {/* <h1 className="text-2xl font-bold mb-5">Case details</h1> */}
      <p className="font-bold mb-2 text-sm text-center">
        Provide basic information about this case.
      </p>
      <Card
        bordered={false}
        style={{
          width: 400,
          paddingBottom: 30,
        }}
      >
        <div className="flex flex-col items-center justify-center lg:p-0 p-10">
          <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mb-1">
            Case title
          </label>
          <Input
            placeholder="E.g. Graduate trainee 2020"
            onChangeText={setTitle}
            styles="w-fit"
            value={title}
          />
          <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mt-5 mb-1">
            Case description
          </label>
          <Input
            placeholder="E.g. Referee collection for Graduate trainee 2020"
            multiline={true}
            onChangeText={setDescription}
            styles="w-fit mb-5 focus:outline-none h-24"
            value={description}
          />
          <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mb-1">
            What company is this for?
          </label>
          <Input
            placeholder="Company name"
            onChangeText={setCompany}
            styles="w-fit mb-5"
            value={company}
          />
          <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold mb-1">
            Minimum number of referees
          </label>
          <InputNumber
            style={{ alignSelf: "flex-start" }}
            min={1}
            type="number"
            max={refereeNumber === -1 ? 999999 : refereeNumber}
            value={numberOfReferences || 0}
            onChange={(e) => setNumberOfReferences(e)}
          />
        </div>
      </Card>
    </div>
  );
}
