import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import firebase from "firebase/app";
import { Modal } from "antd";
import AnswersTable from "./AnswersTable";
import Loading from "../../components/Loading";
import AIStatistics from "../../components/AIStatistics";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function CaseAnswers() {
  usePageTracking();
  const navigate = useNavigate();
  const videoRef = useRef();
  let { id, refereeName } = useParams();
  const [refereeDetails, setRefereesDetails] = useState(null);
  const [loading, toggleLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    videoRef.current.src = null;
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    videoRef.current.src = null;
    setIsModalOpen(false);
  };

  async function getReferees() {
    toggleLoading(true);
    const data = await firebase
      .firestore()
      .collection("cases")
      .doc(`${firebase.auth().currentUser.uid}`)
      .collection("list")
      .doc(id)
      .collection("referrees")
      .where("refereeName", "==", refereeName)
      .get();
    if (!data.empty) {
      const arr = [];
      data.forEach((ref) => {
        arr.push({
          ...ref.data(),
          id: ref.id,
        });
      });
      setRefereesDetails(arr[0]);
      toggleLoading(false);
    }
    toggleLoading(false);
  }

  useEffect(() => {
    getReferees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refereeName]);

  if (loading) {
    return <Loading />;
  }

  function showMediaFunction() {
    showModal();
    setTimeout(() => {
      videoRef.current.src = refereeDetails?.mediaUrl;
    }, 500);
  }
  return (
    <div className="relative md:ml-64 p-12">
      <Modal
        title="Video evidence"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <video
          className="w-1/2 h-1/2 mx-auto mt-5"
          ref={videoRef}
          src={refereeDetails?.mediaUrl}
          controls
          autoPlay
          loop={false}
        />
      </Modal>
      <div
        className="lg:ml-5 mb-10 lg:mt-12 cursor-pointer"
        onClick={() => navigate(`/cases/${id}`)}
      >
        <i className="fas fa-arrow-left text-emerald-500 mr-4 ml-2"></i>
      </div>
      <AnswersTable
        refereeName={refereeDetails?.refereeName}
        showMedia={refereeDetails?.mediaUrl ? showMediaFunction : undefined}
        answers={refereeDetails?.responses.map((response) => {
          return {
            label: response.label,
            answer: response.value,
            type: response.type,
          };
        })}
      />
      <div className="w-full xl:w-10/12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white shadow-lg rounded p-12">
          <p className="font-bold">AI analysis:</p>
          <span className="text-xs whitespace-nowrap mb-3">
            Analysis of the responses for this {refereeDetails?.refereeName}:
          </span>
          <AIStatistics responses={refereeDetails?.responses} />
        </div>
      </div>
    </div>
  );
}
