import { HistoryOutlined } from "@ant-design/icons";
import { List } from "antd";
// import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function BillingHistory({ invoices = [] }) {
  const ListItem = ({ invoice }) => {
    const adminEmail = "admin@botref.com";
    const subject = `Issue with billing ${invoice.id}`;
    const body = `Good day, I would like to report an issue with billings. <Describe issue here>`;
    return (
      <List.Item key={JSON.stringify(invoice)}>
        <div className="border">
          <div className="p-3 flex flex-row">
            <div className="flex flex-col gap-2">
              <span className="mr-auto text-sm text-navy-700 dark:text-white font-bold">
                Plan : {invoice.plan}
              </span>
              <span className="mr-auto text-sm text-navy-700 dark:text-white">
                Payment date: {new Date(invoice.createAt).toDateString()}
              </span>
              <a href={`mailto:${adminEmail}?subject=${subject}&body=${body}`}>
                <span className="mr-auto text-sm text-navy-700 dark:text-white underline cursor-pointer">
                  Report an issue
                </span>
              </a>
            </div>
          </div>
        </div>
      </List.Item>
    );
  };
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white lg:w-2/4 mb-6 shadow-lg rounded mt-12 p-10">
      <div>
        <HistoryOutlined /> <br />
        <span className="mr-auto text-xl text-navy-700 dark:text-white font-bold my-2">
          Billing history:{" "}
          {/* <small className=" font-normal">{getLastBillingDate()}</small> */}
        </span>
        <hr className="my-5" />
        <List
          dataSource={invoices}
          renderItem={(invoice) => <ListItem invoice={invoice} />}
        />
      </div>
    </div>
  );
}
