import React from "react";
import usePageTracking from "../hooks/usePageTracking";

const PrivacyPolicy = () => {
  usePageTracking();
  const date = "November 20 2023";
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-md shadow-md">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

        <p className="mb-4">Effective Date: {date}</p>

        <h2 className="text-xl font-bold mb-2">1. Introduction:</h2>
        <p className="mb-4">
          Welcome to Botref ("we," "our," or "us"). This Privacy Policy explains
          how we collect, use, disclose, and safeguard your information when you
          use our software as a service (SaaS) platform and related services.
        </p>

        <h2 className="text-xl font-bold mb-2">2. Information Collected:</h2>
        <p className="mb-4">We collect the following types of information:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            Personal Information: [Examples, e.g., name, email address, etc.]
          </li>
          <li>
            Non-Personal Information: [Examples, e.g., device information, usage
            data, etc.]
          </li>
        </ul>

        <h2 className="text-xl font-bold mb-2">3. Use of Information:</h2>
        <p className="mb-4">
          We use collected information for the following purposes:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Account creation and management.</li>
          <li>Communication with users.</li>
          <li>Improvement of our services.</li>
          <li>Analytics and research.</li>
        </ul>

        <h2 className="text-xl font-bold mb-2">4. Data Sharing:</h2>
        <p className="mb-4">
          We may share your information in the following circumstances:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            With third-party service providers for platform functionality.
          </li>
          <li>In the event of a merger, acquisition, or sale of assets.</li>
          <li>With your consent or as required by law.</li>
        </ul>

        <h2 className="text-xl font-bold mb-2">5. Security Measures:</h2>
        <p className="mb-4">
          We employ reasonable security measures to protect your information.
          However, no method of transmission over the internet or electronic
          storage is 100% secure.
        </p>

        <h2 className="text-xl font-bold mb-2">6. User Rights:</h2>
        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Access, correct, or delete your personal information.</li>
          <li>Object to the processing of your information.</li>
          <li>Withdraw your consent for data processing.</li>
        </ul>

        <h2 className="text-xl font-bold mb-2">
          7. Cookies and Tracking Technologies:
        </h2>
        <p className="mb-4">
          We use cookies and similar technologies for analytics, user
          preferences.
        </p>

        <h2 className="text-xl font-bold mb-2">8. Third-Party Links:</h2>
        <p className="mb-4">
          Our platform may contain links to third-party websites or services. We
          are not responsible for the privacy practices or content of these
          third parties. We recommend reviewing their privacy policies.
        </p>

        <h2 className="text-xl font-bold mb-2">9. Children's Privacy:</h2>
        <p className="mb-4">
          Our platform is not directed at children under the age of 18. We do
          not knowingly collect personal information from children.
        </p>

        <h2 className="text-xl font-bold mb-2">
          10. Changes to the Privacy Policy:
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy periodically. We will notify you of
          significant changes. Please review the policy regularly.
        </p>

        <h2 className="text-xl font-bold mb-2">11. Data Retention:</h2>
        <p className="mb-4">
          We retain your information for as long as necessary for the purposes
          outlined in this Privacy Policy or as required by law.
        </p>

        <h2 className="text-xl font-bold mb-2">
          12. International Data Transfers:
        </h2>
        <p className="mb-4">
          Your information may be transferred and processed outside your country
          of residence. By using our platform, you consent to such transfers.
        </p>

        <h2 className="text-xl font-bold mb-2">13. Contact Information:</h2>
        <p className="mb-4">
          If you have questions about this Privacy Policy, please contact us at
          admin@botref.com.
        </p>

        <h2 className="text-xl font-bold mb-2">
          14. Legal Basis for Processing:
        </h2>
        <p className="mb-4">
          Our legal basis for processing your information is legitimate
          interests.
        </p>

        <h2 className="text-xl font-bold mb-2">15. GDPR Compliance:</h2>
        <p className="mb-4">
          If you are located in the European Economic Area (EEA), you have
          certain rights under the General Data Protection Regulation (GDPR).
        </p>

        <h2 className="text-xl font-bold mb-2">
          16. California Privacy Rights:
        </h2>
        <p className="mb-4">
          If you are a California resident, you have certain rights under the
          California Consumer Privacy Act (CCPA).
        </p>

        <h2 className="text-xl font-bold mb-2">
          17. Notification of Data Breaches:
        </h2>
        <p className="mb-4">
          In the event of a data breach, we will notify affected users.
        </p>

        {/* <h2 className="text-xl font-bold mb-2">18. Dispute Resolution:</h2>
        <p className="mb-4">
          Any privacy-related disputes will be resolved through mediation.
        </p> */}

        <h2 className="text-xl font-bold mb-2">19. Policy Effective Date:</h2>
        <p className="mb-4">
          This Privacy Policy is effective as of {date} and will remain in
          effect except with respect to any changes in its provisions in the
          future, which will be in effect immediately after being posted on this
          page.
        </p>

        <div className="mt-8">
          <p>Botref</p>
          {/* <p>[Your Address]</p> */}
          <p>admin@botref.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
