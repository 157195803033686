import React, { useState } from "react";
import FormInput from "../components/FormInput";
import FormText from "../components/FormText";
import FormCheckbox from "./FormCheckbox";
import FormRadio from "./FormRadio";
import FormDropDown from "../components/FormDropDown";

export default function FormViwer({
  title = "",
  elementsArray = [],
  handleCancel,
  submitQuestionnaire,
}) {
  const [elements, setElements] = useState(elementsArray);

  const updateElementState = (obj) => {
    const { id } = obj;
    const index = elements.findIndex((item) => item.id === id);
    if (index !== -1) {
      const newTemp = elements[index];
      if (!newTemp) return;
      Object.keys(obj).forEach((key) => {
        newTemp[key] = obj[key];
      });
      const arr = Array.from(elements);
      arr[index] = newTemp;
      setElements(arr);
    }
  };

  const deleteElement = (id) => {
    const filtered = elements.filter((item) => item.id !== id);
    setElements(filtered);
  };

  const Input = ({
    label,
    value,
    id,
    ismultiline,
    elementName,
    placeholder,
    helperText,
  }) => {
    return (
      <FormInput
        formLabel={label}
        formValue={value}
        formHelperText={helperText}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        ismultiline={ismultiline}
        type="input"
        isPreview={true}
        formPlaceHolder={placeholder}
        elementName={elementName}
      />
    );
  };

  const Checkbox = ({ label, value, id, elementName, checked }) => {
    return (
      <FormCheckbox
        formLabel={label}
        value={value}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        type="checkbox"
        elementName={elementName}
        isPreview={true}
        formChecked={checked}
      />
    );
  };

  const Radio = ({ label, value, id, elementName, checked }) => {
    return (
      <FormRadio
        formLabel={label}
        value={value}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        type="radio"
        isPreview={true}
        elementName={elementName}
        formChecked={checked}
      />
    );
  };

  const MultiInput = ({
    label,
    value,
    id,
    ismultiline,
    elementName,
    placeholder,
    helperText,
  }) => {
    return (
      <FormInput
        formLabel={label}
        formValue={value}
        formPlaceHolder={placeholder}
        formHelperText={helperText}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        ismultiline={ismultiline}
        type="input"
        isPreview={true}
        elementName={elementName}
      />
    );
  };

  const Text = ({ label, value, id, elementName }) => {
    return (
      <FormText
        label={label}
        formLabel={label}
        id={id}
        type="text"
        updateFunction={updateElementState}
        isPreview={true}
        elementName={elementName}
      />
    );
  };

  const DropDown = ({
    label,
    value,
    id,
    elementName,
    options,
    selectedOption,
  }) => {
    return (
      <FormDropDown
        label={label}
        formLabel={label}
        formOptions={options}
        formSelectedOption={selectedOption}
        id={id}
        type="select"
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        elementName={elementName}
        isPreview={true}
      />
    );
  };

  const ElementRenderer = (element) => {
    switch (element.type) {
      case "input":
        // code block
        return Input(element);
      case "text":
        // code block
        return Text(element);
      case "textarea":
        // code block
        return MultiInput(element);
      case "checkbox":
        // code block
        return Checkbox(element);
      case "radio":
        // code block
        return Radio(element);
      case "select":
        // code block
        return DropDown(element);
      default:
      // code block
    }
  };

  async function getInputType(name) {
    if (name) {
      const hyphen = name.split("-")[0];
      return hyphen;
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let inputElements = document.getElementById("viewFrom").children;
    inputElements = Array.from(inputElements).filter(
      (element) => element.getAttribute("type") !== "submit"
    );
    let results = [];
    for (let i = 0; i < inputElements.length; i++) {
      const element = inputElements[i];
      // console.log("ele", element);
      const inputType = await getInputType(
        element.children[0].getAttribute("name")
      );
      if (inputType === "input") {
        const inputLabelText =
          element.getElementsByClassName("inputLabelDiv")[0].children[0]
            .textContent;
        const innerInputElement = element.getElementsByTagName("input")[0];
        if (innerInputElement) {
          results.push({
            id: innerInputElement.getAttribute("id"),
            type: innerInputElement.getAttribute("type"),
            placeholder: innerInputElement.getAttribute("placeholder"),
            value: innerInputElement.value,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
      if (inputType === "textarea") {
        const inputLabelText =
          element.getElementsByClassName("inputLabelDiv")[0].children[0]
            .textContent;
        const innerInputElement = element.getElementsByTagName("textarea")[0];
        if (innerInputElement) {
          results.push({
            id: innerInputElement.getAttribute("id"),
            type: "textarea",
            placeholder: innerInputElement.getAttribute("placeholder"),
            value: innerInputElement.value,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
      if (inputType === "checkbox") {
        const inputLabelText =
          element.getElementsByTagName("label")[0]?.innerText;
        const innerInputElement = element.getElementsByTagName("input")[0];
        if (innerInputElement) {
          results.push({
            id: innerInputElement.getAttribute("id"),
            type: "checkbox",
            value: innerInputElement.value,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
      if (inputType === "radio") {
        const inputLabelText =
          element.getElementsByTagName("label")[0]?.innerText;
        const innerInputElement = element.getElementsByTagName("input")[0];
        if (innerInputElement) {
          results.push({
            id: innerInputElement.getAttribute("id"),
            type: "radio",
            value: innerInputElement.value,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
      if (inputType === "text") {
        const inputLabelText =
          element.getElementsByTagName("label")[0]?.innerText;
        const innerInputElement = element.getElementsByTagName("label")[0];
        if (innerInputElement) {
          results.push({
            id: innerInputElement.getAttribute("id"),
            type: "text",
            value: innerInputElement.innerText,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
      if (inputType === "select") {
        const inputLabelText =
          element.getElementsByTagName("label")[0]?.innerText;
        const innerInputElement = element.getElementsByTagName("label")[0];
        const selectedItem = element.getElementsByClassName(
          "ant-select-selection-item"
        )[0]?.textContent;
        if (innerInputElement) {
          results.push({
            id: new Date().getTime(),
            type: "select",
            value: selectedItem || null,
            label: inputLabelText,
            divId: element.children[0].getAttribute("name"),
          });
        }
      }
    }
    // console.log("results", results);
    submitQuestionnaire && submitQuestionnaire(results);
  }

  return (
    <div className="pt-1">
      <div>
        {/* <p className="text-2xl mb-5 text-navy-700 dark:text-white font-bold w-96">
          {title}
        </p>
        <hr /> */}
        <div>
          <form id="viewFrom" onSubmit={handleSubmit}>
            {elements.map((element, index) => (
              <div key={`${element.id}${index}`}>
                {ElementRenderer(element)}
              </div>
            ))}
            <div className="flex items-center justify-end p-6 rounded-b">
              {handleCancel && (
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={(e) => handleCancel && handleCancel(e)}
                >
                  Close
                </button>
              )}
              {elementsArray.length > 0 && (
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

// bon hotel 
// smith city hotel
// new jerusalem ...whacked
// anabel resort
// parktonian
// malibu
// christian spring....suspicious


