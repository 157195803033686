import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { message } from "antd";
import { useReactMediaRecorder } from "react-media-recorder";
import Loading from "../components/Loading";
const STATUS = {
  STARTED: "Recording started",
  STOPPED: "Recording stopped",
};

const INITIAL_COUNT = 10;

export default function VideoEnforcer({submitVideoFunction}) {
  const [record, toggleRecord] = React.useState(true);
  const { startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ video: true });
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [loading, toggleLoading] = useState(true);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  useEffect(() => {
    setTimeout(() => {
      toggleLoading(false);
    }, [500]);
  }, []);

  useEffect(() => {
    if (secondsRemaining <= 0) {
      handleStop();
      stopRecording();
      setSecondsRemaining(INITIAL_COUNT);
      toggleRecord(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsRemaining]);

  const handleStart = () => {
    setStatus(STATUS.STARTED);
  };
  const handleStop = () => {
    setStatus(STATUS.STOPPED);
  };
  /* const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
  }; */
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  const videoConstraints = {
    // width: window.innerWidth,
    // height: window.innerHeight,
    facingMode: "user",
  };

  if (mediaBlobUrl && !record) {
    return (
      <div>
        <video
          className="w-1/2 h-1/2 mx-auto mt-5"
          src={mediaBlobUrl}
          controls
          autoPlay
          loop={false}
        />
        <div className="flex flex-row justify-center mt-5">
          <button
            className="bg-green-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
            onClick={() => {
                message.success('Saving your responses. Please wait')
                submitVideoFunction && submitVideoFunction(mediaBlobUrl);
            }}
          >
            Submit
          </button>
          <button
            className="bg-blue-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
            onClick={() => {
              setTimeout(() => {
                clearBlobUrl();
                toggleRecord(true);
                startRecording();
              }, 100);
            }}
          >
            Record again
          </button>
        </div>
      </div>
    );
  }

  if (!mediaBlobUrl && record) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center pt-5">
          <Webcam
            audio={false}
            // height={window.innerHeight}
            screenshotFormat="image/jpeg"
            width={700}
            style={{ borderRadius: 20 }}
            videoConstraints={videoConstraints}
          />
        </div>
        <div className="text-center">
          <div style={{ padding: 10 }}>
            <span className="text-green-400 font-bold text-sm">
              {" "}
              {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
              {twoDigits(secondsToDisplay)}
            </span>
          </div>
          <div>Status: {status}</div>
        </div>
        <div className="flex flex-row justify-center mt-5">
          {status === STATUS.STOPPED && (
            <button
              className="bg-green-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
              onClick={() => {
                handleStart();
                startRecording();
              }}
            >
              Start recoding
            </button>
          )}
          {status === STATUS.STARTED && (
            <button
              className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
              onClick={() => {
                handleStop();
                stopRecording();
                setSecondsRemaining(INITIAL_COUNT);
                toggleRecord(false);
              }}
            >
              Stop recording
            </button>
          )}
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }
  return <div></div>;
}

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  
  // https://stackoverflow.com/a/2998874/1673761
  const twoDigits = (num) => String(num).padStart(2, "0");
