// import ButtonComp from "../../components/ButtonComp";
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function CaseDashboardHeader({
  title = "",
  company = "",
  data = {},
  editCase,
  mainAction,
  image = "",
  backgroundcolor,
}) {
  const [, setUserNotifications] = useState([]);

  async function getUserNotifications() {
    const userId = firebase.auth().currentUser.uid;
    if (userId) {
      const notifications = await firebase
        .firestore()
        .collection("notifications")
        .doc(userId)
        .collection("cases")
        .doc(data.id)
        .collection("list")
        .get();
      if (!notifications.empty) {
        const arr = [];
        notifications.docs.forEach((noti) => {
          arr.push({
            ...noti.data(),
            id: noti.id,
          });
        });
        setUserNotifications(arr);
      }
    }
  }
  useEffect(() => {
    getUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HeaderButtons = () => {
    return (
      <div className="lg:ml-auto mt-3 ml-5">
        <Button
          onClick={editCase}
          style={{ height: 45, width: 45, marginRight: 10, borderWidth: 2 }}
          icon={<EditOutlined style={{ color: "#fff" }} />}
        ></Button>
      </div>
    );
  };
  return (
    <div
      className="flex lg:flex-row flex-col justify-between pr-5 lg:pt-2"
      style={{ backgroundColor: backgroundcolor }}
    >
      <div className="flex flex-row">
        <div>
          <img
            className="lg:ml-12 ml-5 mt-5 object-contain h-auto w-44 rounded-md"
            src={image}
            alt="company_logo"
          />
          <h1 className="text-white font-bold mt-2 mb-0 lg:ml-12 ml-5 text-2xl">
            {company}
          </h1>
          <h1 className="text-white font-bold mt-0 lg:ml-12 ml-5 text-sm">
            {title}
          </h1>
        </div>
        <div className="ml-auto mt-2 lg:hidden block">
          <HeaderButtons />
        </div>
      </div>
      <div className="lg:block hidden">
        <HeaderButtons />
      </div>
    </div>
  );
}
