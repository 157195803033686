import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import Lottie from "lottie-react";
import { Card, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import empty from "../../assets/animations/empty.json";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import { useUserContext } from "../../contexts/UserContext";
import { Tour } from "antd";
import { useDispatch } from "react-redux";
import { resetCaseData } from "../../redux/caseSlice";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";

const { Meta } = Card;

export default function Cases() {
  usePageTracking();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cases, setCases] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [caseId, setCaseId] = useState(null);
  const [showTour, toggleShowTour] = useState(false);
  const {
    updateUserDetails,
    features,
    loading: contextLoading,
  } = useUserContext();
  const [loading, toggleLoading] = useState(contextLoading);
  async function shouldShowTour() {
    const user = await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get();
    if (user.exists) {
      const userDetails = user.data();
      if (!userDetails?.isCaseTour) {
        toggleShowTour(true);
      }
    }
  }
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: "Let's get started with Cases",
      description:
        "I will walk you through what this is and what you can do and not do here. A case is basically where you define how references are obtained and who your talents are and also view all details about this process.",
      target: () => ref1.current,
    },
    {
      title: "First step",
      description:
        "This is where you create a new case. You need to have a template defined first though.",
      target: () => ref2.current,
    },
    {
      title: "Case templates",
      description:
        "It is important to first create your form in the templates section before you create a case.",
      target: () => ref2.current,
    },
  ];

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function getCases() {
    try {
      toggleLoading(true);
      const userCases = await firebase
        .firestore()
        .collection("cases")
        .doc(`${firebase.auth().currentUser.uid}`)
        .collection("list")
        .get();
      if (!userCases.empty) {
        const arr = [];
        userCases.forEach((caseObj) => {
          arr.push({
            ...caseObj.data(),
            id: caseObj.id,
          });
        });
        setCases(arr);
        toggleLoading(false);
      }
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
    }
  }

  useEffect(() => {
    shouldShowTour();
    getCases();
  }, []);

  const deleteCase = async (id) => {
    setIsModalOpen(false);
    message.success("Deleting...");
    await firebase
      .firestore()
      .collection("cases")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .doc(id)
      .delete()
      .then(() => {
        setCases(cases.filter((cs) => cs.id !== id));
        message.success("Case deleted successfully.");
      })
      .catch(() => message.error("Unable to delete case"));
  };

  const editCase = (id) => {
    navigate(`/cases/edit/${id}`);
  };

  const openCase = (id) => {
    navigate(`/cases/${id}`);
  };

  if (loading) {
    return (
      <div className="relative md:ml-64 bg-blueGray-100">
        <Loading />
      </div>
    );
  }

  if (cases.length === 0) {
    return (
      <div className="relative md:ml-64 bg-blueGray-100">
        <div className="flex items-center justify-center h-screen">
          <div>
            <Lottie
              animationData={empty}
              style={{ width: 300, height: 300 }}
              loop={true}
            />
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-20"
              onClick={() => {
                if (parseInt(features?.caseNumber) === -1) {
                  dispatch(resetCaseData());
                  navigate("/cases/new");
                } else {
                  if (
                    features?.caseNumber &&
                    parseInt(cases.length) + 1 > features?.caseNumber
                  ) {
                    message.error(
                      `You cannot create more than ${cases.length} cases with your current plan.`
                    );
                  } else {
                    dispatch(resetCaseData());
                    navigate("/cases/new");
                  }
                }
              }}
              ref={ref2}
            >
              New case
            </button>
          </div>
        </div>
        <Tour
          open={showTour}
          onClose={() => {
            updateUserDetails({
              isCaseTour: true,
            });
            toggleShowTour(false);
          }}
          mask={false}
          type="primary"
          steps={steps}
        />
      </div>
    );
  }
  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      <Modal
        handleCancel={handleCancel}
        handleOk={handleOk}
        showModal={isModalOpen}
        title="Delete case?"
      >
        <div>
          <div className="flex flex-row mt-5 justify-evenly">
            <button
              onClick={handleCancel}
              className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
            >
              <span>Cancel</span>
            </button>
            <button
              onClick={() => deleteCase(caseId)}
              className="ml-5 bg-red-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
            >
              <span>Delete</span>
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row px-6 border border-solid border-blueGray-100 lg:py-3 py-2 uppercase border-l-0 border-r-0 whitespace-nowrap">
        <h1 className="lg:text-2xl lg:block hidden ml-5 mt-3 font-bold">
          Cases
        </h1>
        <button
          onClick={() => {
            if (parseInt(features?.caseNumber) === -1) {
              dispatch(resetCaseData());
              navigate("/cases/new");
            } else {
              if (
                features?.caseNumber &&
                parseInt(cases.length) + 1 > features?.caseNumber
              ) {
                message.error(
                  `You cannot create more than ${cases.length} cases with your current plan.`
                );
              } else {
                dispatch(resetCaseData());
                navigate("/cases/new");
              }
            }
          }}
          ref={ref2}
          className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm lg:px-6 px-3 lg:py-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ml-auto"
        >
          New Case
        </button>
      </div>
      <div className="lg:grid lg:grid-cols-4 gap-1">
        {cases.map((caseObj) => (
          <Card
            style={{ width: 300, margin: 20, marginRight: 0 }}
            key={caseObj.id}
            actions={[
              <DeleteOutlined
                key="trash"
                onClick={() => {
                  setCaseId(caseObj.id);
                  setIsModalOpen(true);
                }}
              />,
              <EditOutlined key="edit" onClick={() => editCase(caseObj.id)} />,
              <RightCircleOutlined
                key="open"
                onClick={() => openCase(caseObj.id)}
              />,
            ]}
          >
            <Meta title={caseObj.caseData.title} description="" />
          </Card>
        ))}
      </div>
      <Tour
        open={showTour}
        onClose={() => {
          updateUserDetails({
            isCaseTour: true,
          });
          toggleShowTour(false);
        }}
        mask={false}
        type="primary"
        steps={steps}
      />
    </div>
  );
}
