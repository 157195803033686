import React from "react";
import { Descriptions, Card } from "antd";
import { useSelector } from "react-redux";

export default function CaseReview() {
  const { caseData, recipients } = useSelector((state) => state.case);
  const items = [
    {
      key: "1",
      label: "Description",
      children: <p>{caseData.description}</p>,
    },
    {
      key: "2",
      label: "Company",
      children: <p>{caseData.company}</p>,
    },
    {
      key: "3",
      label: "Title",
      children: <p>{caseData.title}</p>,
    },
    {
      key: "4",
      label: "No. of recipients",
      children: (
        <p>
          {`${recipients?.length === 0 ? "0" : recipients?.length}`} recipients
        </p>
      ),
    },
  ];
  return (
    <div className="flex flex-col w-full items-center justify-center pt-12">
      <h1 className="text-2xl font-bold mb-5">Case preview</h1>
      <Card style={{ margin: 4 }}>
        <Descriptions title={caseData.title} items={items} />
      </Card>
    </div>
  );
}
