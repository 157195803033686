import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Collapse } from "antd";

export default function FormInput({
  formLabel = "Text input",
  updateFunction,
  deleteFunction,
  id,
  formValue = "",
  formHelperText = "",
  formPlaceHolder = "Enter placeholder",
  type = "input",
  ismultiline = false,
  formRequired = false,
  isPreview = false,
  elementName,
}) {
  const [value, setValue] = useState(formValue);
  const [helperText, sethelperText] = useState(formHelperText);
  const [placeholder, setPlaceholder] = useState(formPlaceHolder);
  const [, setshowMenu] = useState(false);
  const [, setShowOptions] = useState(formValue);
  const [isRequired,] = useState(formRequired);
  const [richText, setRichText] = useState(formLabel);

  const items = [
    {
      key: "1",
      label: "Set text input properties",
      extra: (
        <MdDelete
          onClick={() => deleteFunc(id)}
          style={{ marginTop: 4 }}
          className="ml-2 text-red-700"
        />
      ),
      children: (
        <div className="flex flex-col">
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Input label
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <label
            htmlFor="helpertext"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Helper text
          </label>
          <input
            type="text"
            name="helpertext"
            defaultValue={helperText}
            placeholder="Enter text"
            onChange={(e) => sethelperText(e.target.value)}
            className="flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
          />
          <label
            htmlFor="placeholder"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Placeholder
          </label>
          <input
            type="text"
            name="placeholder"
            defaultValue={placeholder}
            placeholder="Enter placeholder"
            onChange={(e) => setPlaceholder(e.target.value)}
            className="flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
          />
          {/* <div className="mt-4">
            <Toggle value={isRequired} changeFunction={toggleIsRequired} />
          </div> */}
        </div>
      ),
    },
  ];

  const update = () => {
    const obj = {
      label: richText,
      value,
      helperText,
      placeholder,
      type,
      id,
      isRequired,
    };
    updateFunction(obj);
  };

  const onChange = (key) => {
    // console.log(key);
  };

  const deleteFunc = (id) => {
    deleteFunction(id);
  };
  return (
    <div
      name={elementName}
      className={`my-3 h-fit cursor-pointer w-fit ${
        !isPreview && "border border-solid border-blueGray-100"
      } p-5`}
      key={id}
      onMouseLeave={() => {
        setShowOptions(false);
        setshowMenu(false);
        update();
      }}
      onMouseEnter={() => {
        if (isPreview) return;
        setShowOptions(true);
      }}
    >
      <div id="inputLabelDiv" className="inputLabelDiv">
        {richText && (
          <div dangerouslySetInnerHTML={{ __html: `${richText}` }} />
        )}
      </div>
      <>
        {!ismultiline ? (
          <input
            type="text"
            id={id}
            defaultValue={value}
            // disabled={isPreview}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
            className="w-96 flex h-12 mt-2 items-center bg-gray-50 justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
          />
        ) : (
          <textarea
            id={id}
            defaultValue={value}
            placeholder={placeholder}
            // disabled={isPreview}
            onChange={(e) => setValue(e.target.value)}
            rows="4"
            className="block p-2.5 mt-2 text-sm text-gray-900 bg-white outline-none rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
          ></textarea>
        )}

        {helperText && (
          <small className="ml-1 text-red-700">{helperText}</small>
        )}
      </>
      {!isPreview && (
        <div className="w-96 mt-4">
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
      )}
      {/* {showOptions && (
        <div className="flex flex-row mt-3">
          <FaEdit onClick={() => setshowMenu(!showMenu)} />
          <MdDelete
            onClick={() => deleteFunc(id)}
            className="ml-2 text-red-700"
          />
        </div>
      )} */}
      {/* {showMenu && (
        <div className="flex flex-col mt-3 w-96">
          <small className="font-bold mb-3 text-xl text-center">
            Edit details
          </small>
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Input label
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <label
            htmlFor="helpertext"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Helper text
          </label>
          <input
            type="text"
            name="helpertext"
            defaultValue={helperText}
            placeholder="Enter text"
            onChange={(e) => sethelperText(e.target.value)}
            className="w-96 flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
          />
          <label
            htmlFor="placeholder"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Placeholder
          </label>
          <input
            type="text"
            name="placeholder"
            defaultValue={placeholder}
            placeholder="Enter placeholder"
            onChange={(e) => setPlaceholder(e.target.value)}
            className="w-96 flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
          />
          <div className="mt-4">
            <Toggle value={isRequired} changeFunction={toggleIsRequired} />
          </div>
        </div>
      )} */}
    </div>
  );
}
