import React from "react";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";

export default function BillingOverview({ userDetails, invoices = [] }) {
  const navigate = useNavigate();
  const getLastBillingDate = () => {
    if (!userDetails?.nextPayment || invoices.length === 0) {
      return "You currently do not any active plan.";
    } else {
      const lastDate = new Date(userDetails?.nextPayment.toDate());
      var newDate = new Date(lastDate.setMonth(lastDate.getMonth() + 1));
      return newDate.toDateString();
    }
  };

  const doCancelPlan = async () => {
    try {
      const body = {
        subscriptionId: userDetails.subscriptionId,
        userId: firebase.auth().currentUser.uid
      };
      await api.post("/payment/cancelSubscription", body);
      toast.success(
        "Success! You are now on the free plan."
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error('Unable to cancel plan.');
    }
  };
  if (userDetails) {
    return (
      <div className="relative flex flex-col min-w-0 break-words bg-white lg:w-2/4 mb-6 shadow-lg rounded mt-12 p-10">
        <ToastContainer />
        <div className="flex flex-col">
          {userDetails.cancelationRequest && (
            <p className="text-red-500 text-sm font-bold">
              We have received your cancellation request. Please note these
              records here might not be valid when your current payment plan
              expires.
            </p>
          )}
          <span className="mr-auto text-sm text-navy-700 dark:text-white font-bold my-2">
            <SafetyCertificateOutlined /> <br />
            Your current plan: Botref {userDetails.plan}
          </span>
          <button
            onClick={() => {
              navigate("/billings/pricing");
            }}
            className="bg-blue-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
          >
            {userDetails.plan !== "Free" ? "Select plan" : "Change plan"}
          </button>
        </div>
        <hr className="my-5" />
        <div>
          <span className="mr-auto text-sm text-navy-700 dark:text-white font-bold my-2">
            Upcoming invoice:{" "}
            <span className="font-normal">{getLastBillingDate()}</span>
          </span>
          <br />
          {userDetails.plan !== "Free" && (
            <button
              onClick={() => {
                doCancelPlan();
              }}
              className="bg-red-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
            >
              Cancel plan
            </button>
          )}
        </div>
      </div>
    );
  }
}
