import React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Sidebar from "./Sidebar";

import { useAuthListener } from "../utils/firebaseHooks";

const ProtectedRoute = ({ component: Component }) => {
  let location = useLocation();
  // a custom hook to keep track of user's auth status
  const { loggedIn, checkingStatus } = useAuthListener();

  React.useEffect(() => {
    // Google Analytics
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Sidebar />
      {
        // display a spinner while auth status being checked
        checkingStatus ? (
          <div style={{ marginTop: window.innerHeight / 3 }}>
            {/* <Loading /> */}
          </div>
        ) : loggedIn ? (
          // if user is logged in, grant the access to the route
          // note: in this example component is Bar
          <Component />
        ) : (
          // else render an unauthorised component
          // stating the reason why it cannot access the route
          <Navigate to="/login" />
        )
      }
    </>
  );
};

export default ProtectedRoute;
