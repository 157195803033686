import React, { useState, useEffect } from "react";
import { List, message } from "antd";
import {
  BellOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import Loading from "../components/Loading";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function Notifications() {
  let { caseId } = useParams();
  const [userNotifications, setUserNotifications] = useState([]);
  const [state, setState] = useState(new Date().getTime());
  const [loading, toggleLoading] = useState(true);

  async function getUserNotifications() {
    const userId = firebase.auth().currentUser.uid;
    if (userId) {
      const notifications = await firebase
        .firestore()
        .collection("notifications")
        .doc(userId)
        .collection("cases")
        .doc(caseId)
        .collection("list")
        .get();
      if (!notifications.empty) {
        const arr = [];
        notifications.docs.forEach((noti) => {
          arr.push({
            ...noti.data(),
            id: noti.id,
          });
        });
        setUserNotifications(arr);
      }
    }
    toggleLoading(false);
  }

  useEffect(() => {
    getUserNotifications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  async function deleteNotification(id) {
    await firebase
      .firestore()
      .collection("notifications")
      .doc(firebase.auth().currentUser.uid)
      .collection("cases")
      .doc(caseId)
      .collection("list")
      .doc(id)
      .delete();
    setUserNotifications(userNotifications.filter((not) => not.id !== id));
    message.success("Deleted");
  }

  async function markNotificationAsSeen(id) {
    await firebase
      .firestore()
      .collection("notifications")
      .doc(firebase.auth().currentUser.uid)
      .collection("cases")
      .doc(caseId)
      .collection("list")
      .doc(id)
      .update({
        isSeen: true,
      });
    setState(new Date().getTime());
    message.success("Marked as read");
  }

  const ShowViewed = ({ isSeen, id }) => {
    return (
      <button
        type="button"
        className="bg-green-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => {
          if (!isSeen) {
            markNotificationAsSeen(id);
          }
        }}
      >
        {isSeen ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </button>
    );
  };

  if(loading) return <Loading />

  return (
    <div className="relative md:ml-64 p-12">
      <div
        className="mb-10 lg:mt-5 cursor-pointer flex flex-row"
        onClick={() => window.history.back()}
      >
        <i className="fas fa-arrow-left mr-2 ml-2 mt-1"></i>
        <span>Back</span>
      </div>
      <p className="text-3xl font-semibold">Notficiations</p>
      <hr className="my-5" />
      <List
        itemLayout="horizontal"
        dataSource={userNotifications}
        // style={{ width: "40vw" }}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <ShowViewed isSeen={item.isSeen} id={item.id} />,
              <button
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  deleteNotification(item.id);
                }}
              >
                <DeleteOutlined />
              </button>,
            ]}
          >
            <List.Item.Meta
              key={index}
              avatar={<BellOutlined style={{ fontSize: 30 }} />}
              title={<p>{item.title}</p>}
              description={item.details}
            />
          </List.Item>
        )}
      />
    </div>
  );
}
