import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import FormInput from "../components/FormInput";
import FormText from "../components/FormText";
import FormCheckbox from "../components/FormCheckbox";
// import FormRadio from "../components/FormRadio";
import FormDropDown from "../components/FormDropDown";
import Preview from "./Preview";
import { useUserContext } from "../contexts/UserContext";
import { message, Modal, Tour } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragOutlined } from "@ant-design/icons";
import ElementToolbar from "./ElementToolbar";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function FormBuilder({
  save,
  savedElements = [],
  formName = null,
}) {
  const [elements, setElements] = useState(savedElements);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { updateUserDetails } = useUserContext();
  const [showTour, toggleShowTour] = useState(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const steps = [
    {
      title: 'Welcome to the form builder',
      description: 'This is a drag and drop editor where you can define what questions you want to ask and in what format you want them to appear.',
      target: () => ref1.current,
    },
    {
      title: 'Form elements',
      description: 'Here you can select what fields you wish referees to enter answers to the questions you ask them.',
      target: () => ref2.current,
    },
    {
      title: 'Preview',
      description: 'Click here to have a preview of what your form will look and feel like',
      target: () => ref3.current,
    },
    {
      title: 'Save',
      description: 'Click here to save your form.',
      target: () => ref4.current,
    },
    {
      title: 'Clear form',
      description: 'Click here to delete your current form design.',
      target: () => ref5.current,
    },
  ];

  async function shouldShowTour() {
    const user = await firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .get();
    if (user.exists) {
      const userDetails = user.data();
      if (!userDetails?.isFormBuilderTour) {
        toggleShowTour(true);
      }
    }
  }
  useEffect(() => {
    shouldShowTour();
  }, [])
  //ADD NEXT STEPS IN ITS OWN DASHBOARD PAGE
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const addElement = (type) => {
    switch (type) {
      case "input":
        // code block
        const element = {
          type: "input",
          id: uuidv4(),
          label: "",
          placeholder: "",
          helperText: "",
          value: "",
          elementName: `input-${uuidv4()}`,
          ismultiline: false,
        };
        addElementToArray(element);
        break;

      case "textarea":
        // code block
        const textareaelement = {
          type: "input",
          id: uuidv4(),
          elementName: `textarea-${uuidv4()}`,
          label: "",
          placeholder: "",
          helperText: "",
          value: "",
          ismultiline: true,
        };
        addElementToArray(textareaelement);
        break;

      case "checkbox":
        // code block
        const checkboxelement = {
          type: "checkbox",
          id: uuidv4(),
          elementName: `checkbox-${uuidv4()}`,
          label: "",
          placeholder: "",
          checked: false,
        };
        addElementToArray(checkboxelement);
        break;

      case "text":
        // code block
        const text = {
          type: "text",
          elementName: `text-${uuidv4()}`,
          id: uuidv4(),
          label: "",
          placeholder: "",
        };
        addElementToArray(text);
        break;

      case "select":
        // code block
        const select = {
          type: "select",
          elementName: `select-${uuidv4()}`,
          id: uuidv4(),
          options: [],
          selectedOption: null,
          label: "",
          placeholder: "",
        };
        addElementToArray(select);
        break;
      default:
      // code block
    }
  };
  const addElementToArray = (element) => {
    setElements([...elements, element]);
  };

  const updateElementState = (obj) => {
    const { id } = obj;
    const index = elements.findIndex((item) => item.id === id);
    if (index !== -1) {
      const newTemp = elements[index];
      if (!newTemp) return;
      Object.keys(obj).forEach((key) => {
        newTemp[key] = obj[key];
      });
      const arr = Array.from(elements);
      arr[index] = newTemp;
      setElements(arr);
    }
  };

  const deleteElement = (id) => {
    const filtered = elements.filter((item) => item.id !== id);
    setElements(filtered);
  };

  const Input = ({
    label,
    value,
    id,
    ismultiline,
    elementName,
    helperText,
    isRequired,
  }) => {
    return (
      <FormInput
        formLabel={label}
        formValue={value}
        formHelperText={helperText}
        id={id}
        updateFunction={updateElementState}
        formRequired={isRequired}
        deleteFunction={deleteElement}
        ismultiline={ismultiline}
        type="input"
        elementName={elementName}
      />
    );
  };

  const Checkbox = ({ label, value, id, elementName, checked, isRequired }) => {
    return (
      <FormCheckbox
        formLabel={label}
        value={value}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        type="checkbox"
        elementName={elementName}
        formRequired={isRequired}
        formChecked={checked}
      />
    );
  };

  const MultiInput = ({
    label,
    value,
    id,
    ismultiline,
    elementName,
    isRequired,
  }) => {
    return (
      <FormInput
        formLabel={label}
        formValue={value}
        id={id}
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        ismultiline={ismultiline}
        type="input"
        elementName={elementName}
        formRequired={isRequired}
      />
    );
  };

  const Text = ({ label, value, id, elementName, isRequired }) => {
    return (
      <FormText
        label={label}
        formLabel={label}
        id={id}
        type="text"
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        elementName={elementName}
        formRequired={isRequired}
      />
    );
  };

  const DropDown = ({
    label,
    value,
    id,
    elementName,
    options,
    selectedOption,
    isRequired,
  }) => {
    return (
      <FormDropDown
        label={label}
        formLabel={label}
        formOptions={options}
        formSelectedOption={selectedOption}
        id={id}
        type="select"
        updateFunction={updateElementState}
        deleteFunction={deleteElement}
        elementName={elementName}
        formRequired={isRequired}
      />
    );
  };

  const ElementRenderer = (element) => {
    switch (element.type) {
      case "input":
        // code block
        return Input(element);
      case "text":
        // code block
        return Text(element);
      case "textarea":
        // code block
        return MultiInput(element);
      case "checkbox":
        // code block
        return Checkbox(element);
      case "select":
        // code block
        return DropDown(element);
      default:
      // code block
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = ({ destination, source }) => {
    if (!destination) return;

    setElements(reorder(elements, source.index, destination.index));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div>
        <div className="flex lg:flex-row flex-col lg:justify-center">
          <div className="h-screen flex flex-col">
            <div className="w-full mb-10 mt-2 shadow-xl">
              <div className="flex justify-evenly flex-row my-3">
                <button
                  ref={ref3}
                  className="bg-blue-300 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
                  onClick={() => showModal()}
                >
                  <i className="fas fa-eye text-white mr-4 ml-4"></i>
                </button>
                <button
                  ref={ref4}
                  className="bg-emerald-500 mx-5 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
                  onClick={() => {
                    if (elements.length === 0) {
                      message.error("The template is empty");
                      return;
                    }
                    save(elements, formName);
                  }}
                >
                  <i className="fas fa-save text-white mr-4 ml-4"></i>
                </button>
                <button
                  ref={ref5}
                  className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-1 ease-linear transition-all duration-150"
                  onClick={() => setElements([])}
                >
                  <i className="fas fa-trash text-white mr-4 ml-4"></i>
                </button>
              </div>
            </div>
            <div className="border-2 min-w-[500px] h-screen shadow-xl overflow-y-scroll p-5">
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {elements.map((element, index) => (
                      <Draggable
                        key={`${element.id}${index}`}
                        index={index}
                        draggableId={`${element.id}${index}`}
                        direction="vertical"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              // default item style
                              padding: "4px 16px",
                              // default drag style
                              ...provided.draggableProps.style,
                              // customized drag style
                              background: snapshot.isDragging
                                ? "#3498db"
                                : "transparent",
                            }}
                          >
                            <div>
                              <div className="flex flex-row-reverse">
                                <DragOutlined />
                              </div>
                              {ElementRenderer(element)}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className="lg:ml-20 m-10 lg:mt-32 h-fit max-w-fit" ref={ref2}>
            <div className="flex flex-row">
              <MenuUnfoldOutlined />
              <label className="mr-auto text-sm text-navy-700 dark:text-white font-bold ml-3">
                Form elements
              </label>
            </div>
            <ElementToolbar add={addElement} />
          </div>
        </div>
        {isModalOpen && (
          <Modal
            footer={null}
            onCancel={handleCancel}
            onOk={handleOk}
            open={isModalOpen}
          >
            <Preview elements={elements} handleCancel={handleCancel} />
          </Modal>
        )}
        <Tour open={showTour}         
          onClose={() => {
            updateUserDetails({
              isFormBuilderTour: true,
            });
            toggleShowTour(false);
          }} mask={false} type="primary" steps={steps} />
      </div>
    </DragDropContext>
  );
}
