import React, { useState, useEffect } from "react";
import Input from "../../components/Input";
import { Tag, Result } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import validator from "validator";
import firebase from "firebase/app";
import api from "../../api";
import Loading from "../../components/Loading";
import { RecipientStatus, RefereeStatus } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function SubmiReference() {
  usePageTracking();
  // todo add custom messages while sending to request referees from talents
  const { userId, caseId, userFullName, recipientId, formTemplateId } =
    useParams();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [referees, setReferees] = useState([]);
  const [caseData, setCaseData] = useState(null);
  const [numberOfReferences, setNumberOfReferences] = useState(1);
  const [loading, toggleLoading] = useState(false);
  const [recipientData, setRecipientData] = useState(null);
  const [done, setDone] = useState(false);

  async function getCaseData() {
    try {
      toggleLoading(true);
      const data = await firebase
        .firestore()
        .collection("cases")
        .doc(`${userId}`)
        .collection("list")
        .doc(caseId)
        .get();
      if (data.exists) {
        toggleLoading(false);
        setCaseData({
          ...data.data().caseData,
          id: data.id,
        });
        setNumberOfReferences(data.data().caseData.numberOfReferences);
      }
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    getRecipientStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecipientStatus = async () => {
    try {
      toggleLoading(true);
      const recip = await firebase
        .firestore()
        .collection("cases")
        .doc(`${userId}`)
        .collection("list")
        .doc(caseId)
        .collection("recipients")
        .doc(recipientId)
        .get();
      if (recip.exists) {
        setRecipientData({
          ...recip.data(),
          id: recip.id,
        });
        if (
          recip.data().status ===
          RecipientStatus.USER_REFEREE_REQUESTED_FOR_RESPONSE
        ) {
          toggleLoading(false);
          setDone(true);
        } else {
          await getCaseData();
        }
      } else {
        await getCaseData();
      }
    } catch (error) {
      toggleLoading(false);
      Sentry.captureException(error);
    }
  };

  const addRecipient = () => {
    if (!email || !name || !validator.isEmail(email)) return;
    if (
      referees.find(
        (referee) => referee.name === name || referee.email === email
      )
    ) {
      toast.error("You cannot enter the same referee details twice.");
      return;
    }
    setReferees([
      ...referees,
      {
        email,
        name,
      },
    ]);
    setEmail("");
    setName("");
  };

  const updateRecipientStatus = async () => {
    await firebase
      .firestore()
      .collection("cases")
      .doc(`${userId}`)
      .collection("list")
      .doc(caseId)
      .collection("recipients")
      .doc(recipientId)
      .update({
        status: RecipientStatus.USER_REFEREE_REQUESTED_FOR_RESPONSE,
      }).catch((error) =>  Sentry.captureException(error));
  };

  const requestDetailsFromReferees = async () => {
    try {
      const caseObject = await firebase
        .firestore()
        .collection("cases")
        .doc(userId)
        .collection("list")
        .doc(caseId)
        .get();
      if (caseObject.exists) {
        const {
          caseData: { company, imageUrl, color, title },
        } = caseObject.data();
        const recipientData = await firebase
          .firestore()
          .collection("cases")
          .doc(`${userId}`)
          .collection("list")
          .doc(caseId)
          .collection("recipients")
          .doc(recipientId)
          .get();
        if (recipientData.exists) {
          const postBody = {
            referees,
            companyName: company,
            talentName: userFullName.replaceAll("+", " "),
            caseId,
            userId,
            formTemplateId: formTemplateId,
            recipientId: recipientId,
            image: imageUrl,
            color: color,
            stage: recipientData.data().stage,
          };
          await api.post("/request/referee", postBody);
          if (caseData?.emailInform) {
            const userDetails = await firebase
              .firestore()
              .collection("users")
              .doc(userId)
              .get();
            if (userDetails.exists) {
              const informOwnerBody = {
                companyName: company,
                talentName: userFullName.replaceAll("+", " "),
                email: userDetails.data().email,
                image: imageUrl,
                color: color,
                caseId,
                caseName: title,
              };
              await api.post(
                "/referee/informownertalentsubmission",
                informOwnerBody
              );
            }
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };


  const submitReferences = async () => {
    try {
      toast.loading("Submitting referee data. Please wait");
      let db = firebase.firestore();
      let batch = db.batch();
      referees.forEach((doc) => {
        let docRef = db
          .collection("cases")
          .doc(userId)
          .collection("list")
          .doc(caseId)
          .collection("referrees")
          .doc(); //automatically generate unique id
        batch.set(docRef, {
          talentName: userFullName.replaceAll("+", " "),
          refereeName: doc.name,
          refereeEmail: doc.email,
          userId,
          caseId,
          createdAt: new Date().toISOString(),
          status: RefereeStatus.RESPONSE_REQUESTED,
          stage: recipientData.stage,
        });
      });
      await batch.commit();
      await requestDetailsFromReferees();
      await updateRecipientStatus();
      // await addNotification();
      setReferees([]);
      setEmail(null);
      setName(null);
      toast.success(
        "We will reach out to your referees. Please nudge them to check their mailboxes."
      );
      setDone(true);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const deleteRecipient = (name) => {
    const arr = Array.from(referees);
    const recipientIndex = arr.findIndex((recip) => recip.name === name);
    arr.splice(recipientIndex, 1);
    setReferees(arr);
  };

  const RecipientItem = ({ name }) => {
    return (
      <div>
        <Tag
          style={{ fontSize: 10, padding: 10 }}
          closeIcon={<CloseCircleOutlined style={{ fontSize: 15 }} />}
          onClose={() => deleteRecipient(name)}
        >
          {name}
        </Tag>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  if (done) {
    return (
      <Result
        status="success"
        title="Successfully submitted your referee details. We will now reach out to them."
        subTitle="Please nudge them to check their inbox."
      />
    );
  }

  if (caseData) {
    return (
      <div className="flex flex-row min-h-screen justify-center">
        <ToastContainer />
        <div class="w-fit m-auto">
          <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h1 className="text-xl font-bold text-center mb-5">
              Add your referee(s) contact information for {caseData.company}
            </h1>
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-1"
                for="username"
              >
                Referee email
              </label>
              <Input
                placeholder="Email address"
                onChangeText={setEmail}
                value={email}
              />
              {email && !validator.isEmail(email) && (
                <small className="text-red-500">Enter a valid email.</small>
              )}
            </div>
            <div class="mb-6">
              <label
                class="block text-gray-700 text-sm font-bold"
                for="password"
              >
                Referee full name
              </label>
              <Input
                placeholder="Full name"
                onChangeText={setName}
                value={name}
                styles="mt-1"
              />
              <small className="text-red-500 font-bold">
                Minium amount of referees required: {numberOfReferences}
              </small>
            </div>
            <div>
              <button
                class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => addRecipient()}
              >
                Add referee to list
              </button>
              <div className="grid grid-cols-4 my-5 overflow-scroll gap-0">
                {referees.map(({ name }, index) => (
                  <div key={`${name}-${index}`} className="m-0">
                    <RecipientItem name={name} />
                  </div>
                ))}
              </div>
            </div>
            <div class="flex w-full">
              <button
                class="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                type="button"
                onClick={() => {
                  if (referees.length < numberOfReferences) {
                    toast.error(
                      `You need to add at least ${numberOfReferences} number of referee contact(s).`
                    );
                    return;
                  }
                  submitReferences();
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <p class="text-center text-gray-500 text-xs">
            &copy;{new Date().getFullYear()} Botref. All rights reserved.
          </p>
        </div>
      </div>
    );
  }
}
