import React, { useState } from "react";
import { Select, Space } from "antd";
import { MdDelete } from "react-icons/md";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CloseCircleOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Tag, Collapse } from "antd";

export default function FormDropDown({
  formLabel = "Custom text",
  formOptions = [],
  formSelectedOption,
  updateFunction,
  deleteFunction,
  id,
  inputName = "",
  type = "select",
  formRequired = false,
  isPreview = false,
  elementName,
}) {
  const [name] = useState(inputName);
  const [, setshowMenu] = useState(false);
  const [, setShowOptions] = useState(false);
  const [richText, setRichText] = useState(formLabel);
  const [isRequired] = useState(formRequired);
  const [selectedOption, setSelectedOption] = useState(formSelectedOption);
  const [options, updateOptions] = useState(formOptions);
  const [optionName, updateOptionName] = useState(null);
  const items = [
    {
      key: "1",
      label: "Set drop down properties",
      extra: (
        <MdDelete
          onClick={() => deleteFunc(id)}
          style={{ marginTop: 4 }}
          className="ml-2 text-red-700"
        />
      ),
      children: (
        <div className="flex flex-col mt-3">
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Enter label for drop down
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <div className="mt-5">
            <label
              htmlFor="inputlabel"
              className="text-sm text-navy-700 dark:text-white font-bold mt-3"
            >
              Add user options
            </label>
            <input
              type="text"
              name="inputlabel"
              value={optionName}
              placeholder="Enter text"
              onChange={(e) => updateOptionName(e.target.value)}
              className="w-48 flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
            />
            <button
              className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 mt-3"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => addOption()}
            >
              Add option
            </button>
            <div className="mt-2">
              {options.map((option) => (
                <Tag
                  key={option.label}
                  style={{ fontSize: 12, padding: 10, margin: 2 }}
                  closeIcon={<CloseCircleOutlined style={{ fontSize: 15 }} />}
                  onClose={() => deleteOption(option.value)}
                >
                  {option.value}
                </Tag>
              ))}
            </div>
          </div>
        </div>
      ),
    },
  ];
  const onChange = (key) => {
    // console.log(key);
  };
  const update = () => {
    const obj = {
      label: richText,
      value: selectedOption,
      options,
      selectedOption,
      name,
      type,
      id,
      isRequired,
    };
    updateFunction(obj);
  };

  const deleteFunc = (id) => {
    deleteFunction(id);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const addOption = () => {
    if (optionName) {
      updateOptions([
        ...options,
        {
          value: optionName,
          label: optionName,
        },
      ]);
      updateOptionName(null);
    }
  };

  const deleteOption = (value) => {
    updateOptions(options.filter((option) => option.value !== value));
  };

  return (
    <div
      name={elementName}
      className={`ml-2 mb-0 h-fit cursor-pointer p-3 w-fit my-5 pt-5 ${
        !isPreview && "border border-solid border-blueGray-100"
      }`}
      key={id}
      id={id}
      elementtype="select"
      onMouseLeave={() => {
        setShowOptions(false);
        setshowMenu(false);
        update();
      }}
      onMouseEnter={() => {
        if (isPreview) return;
        setShowOptions(true);
      }}
    >
      <label className="mb-2">
        {richText && (
          <div dangerouslySetInnerHTML={{ __html: `${richText}` }} />
        )}
      </label>
      <Space wrap>
        <Select
          defaultValue={selectedOption}
          style={{
            width: 120,
            marginTop: 5,
          }}
          value={selectedOption}
          onChange={handleChange}
          options={options}
        />
      </Space>
      {!isPreview && (
        <div className="w-96 mt-4">
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
      )}
      {/* {showOptions && (
        <div className="flex flex-row mt-3">
          <FaEdit onClick={() => setshowMenu(!showMenu)} />
          <MdDelete
            onClick={() => deleteFunc(id)}
            className="ml-2 text-red-700"
          />
        </div>
      )} */}
      {/* {showMenu && (
        <div className="flex flex-col mt-3">
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Enter label for drop down
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <div className="mt-5">
            <label
              htmlFor="inputlabel"
              className="text-sm text-navy-700 dark:text-white font-bold mt-3"
            >
              Option
            </label>
            <input
              type="text"
              name="inputlabel"
              value={optionName}
              placeholder="Enter text"
              onChange={(e) => updateOptionName(e.target.value)}
              className="w-48 flex h-8 items-center justify-center border bg-white/0 p-3 text-sm outline-none border-gray-500"
            />
            <button
              className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 mt-3"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => addOption()}
            >
              Add option
            </button>
            <div className="mt-2">
              {options.map((option) => (
                <Tag
                  key={option.label}
                  style={{ fontSize: 12, padding: 10, margin: 2 }}
                  closeIcon={<CloseCircleOutlined style={{ fontSize: 15 }} />}
                  onClose={() => deleteOption(option.value)}
                >
                  {option.value}
                </Tag>
              ))}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
