import React, { useState } from "react";
import FormBuilder from "../../features/FormBuilder";
import firebase from "firebase/app";
import Modal from "../../components/Modal";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import useWindowSize from "../../hooks/useWindowsDimensions";
import ScreenSizeBlocker from "../../components/ScreensizeBlocker";
import { ToastContainer, toast } from "react-toastify";
import usePageTracking from "../../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function NewTemplate() {
  usePageTracking();
  const navigate = useNavigate();
  const [width,] = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [, toggleLoading] = useState(false);
  const [elements, setElements] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await saveToDatabase(elements, templateName);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const save = (objs, formName) => {
    if (formName) {
      setTemplateName(formName);
    }
    showModal();
    setElements(objs);
  };

  const saveToDatabase = async (objs, templateName) => {
    toggleLoading(true);
    firebase
      .firestore()
      .collection("templates")
      .doc(firebase.auth().currentUser.uid)
      .collection("list")
      .add({
        title: templateName,
        elements: objs,
      })
      .then(() => {
        navigate("/templates");
        message.success("Saved to database.");
        toggleLoading(false);
      })
      .catch((error) => {
        toggleLoading(false);
        Sentry.captureException(error);
        toast.error("Unable to save template");
      });
  };

  if (width <= 600) {
    return (
      <ScreenSizeBlocker />
    );
  }

  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      <ToastContainer />
      {isModalOpen && (
        <Modal
          handleCancel={handleCancel}
          handleOk={handleOk}
          showModal={isModalOpen}
          title="Save template"
        >
          <div>
            <input
              className="border-2 rounded-md px-5 mx-1 h-10"
              placeholder="Enter template name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
            <div className="flex flex-row mt-5 justify-evenly">
              <button
                onClick={handleCancel}
                className="bg-red-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={handleOk}
                className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      <FormBuilder save={save} />
    </div>
  );
}
