import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import usePageTracking from "../hooks/usePageTracking";
import "react-toastify/dist/ReactToastify.css";
import "firebase/firebase-firestore";
import "firebase/auth";

export default function Settings() {
  usePageTracking();
  const navigate = useNavigate();
  const [loading, toggleLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      toggleLoading(false);
    }, 1000);
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function deleteAccount() {
    try {
      toast.loading("Deleting account and all records...Please wait.");
      const body = {
        email: firebase.auth().currentUser.email,
        userId: firebase.auth().currentUser.uid,
      };
      const response = await api.post("/user/deleteAccount", body);
      if (response.status === 200) {
        await firebase.auth().signOut();
        navigate("/login");
      } else {
        toast.error("Unable to delete your account. Please try again");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="relative md:ml-64 p-12">
      <ToastContainer />
      {isModalOpen && (
        <Modal
          handleCancel={handleCancel}
          handleOk={handleOk}
          showModal={isModalOpen}
          title="Delete all records?"
        >
          <div>
            <p>
              This will delete your account and all associated records with it.
            </p>
            <div className="flex flex-row mt-5 justify-evenly">
              <button
                onClick={handleCancel}
                className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={deleteAccount}
                className="bg-red-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Delete</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      <p className="mr-auto text-2xl text-navy-700 dark:text-white font-bold">
        Settings
      </p>
      <div className="relative flex flex-col min-w-0 break-words bg-white lg:w-2/4 mb-6 shadow-lg rounded mt-12 p-10">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="mr-auto text-sm text-navy-700 dark:text-white font-bold mt-2">
              Delete account
            </span>
            <small>
              This is a permanent action and will delete all your records.
            </small>
            <button
              onClick={() => {
                showModal();
              }}
              className="bg-red-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
            >
              <i className="fas fa-trash text-white mr-4 ml-4"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
