import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import { Space } from "antd";

export default function Loading() {
  return (
    <div className="flex flex-row min-h-screen justify-center">
      <Space size="middle">
        <Lottie
          animationData={loadingAnimation}
          style={{ width: 200, height: 200 }}
          loop={true}
        />
      </Space>
    </div>
  );
}
