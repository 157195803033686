import React from "react";
import Modal from "../components/Modal";
import { headerHTML } from "./html-templates/headerHTML";

export default function EmailTemplateViewer({
  isModalOpen,
  handleCancel,
  handleOk,
  content = "",
}) {
  return (
    <div>
      {isModalOpen && (
        <Modal
          showModal={isModalOpen}
          title={null}
          footer={null}
          handleCancel={handleCancel}
          onOk={handleOk}
          open={isModalOpen}
        >
          <div className="flex flex-col w-fit">
            <div className="mb-5">
              <div
                dangerouslySetInnerHTML={{ __html: `${headerHTML(content)}` }}
              />
            </div>
            <button
              onClick={() => handleCancel()}
              className="bg-red-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
            >
              <span>Hide</span>
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
