import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/animations/ScreenBlockAnimation.json";

export default function ScreenSizeBlocker() {
  return (
    <div className="flex flex-col h-screen justify-center items-center p-5">
      <Lottie
        animationData={animationData}
        style={{ width: 300, height: 300 }}
        loop={true}
      />
      <p className="font-bold text-red-300 text-center text-xl">
        For a seamless experience, you can only access the form editor from a desktop or laptop screen.
      </p>
      <small className="mt-5 font-bold">Every other feature is available</small>
    </div>
  );
}
