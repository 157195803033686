import React, { useState, useEffect } from "react";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firebase-firestore";

export const UserContext = React.createContext();

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context) {
    return context;
  }
};

export default function UserProvider({ children }) {
  const [userDetails, setUserDetails] = useState({});
  const [features, setFeatures] = useState({});
  const [loading, toggleLoading] = useState(true);

  useEffect(() => {
    async function getUserDetails() {
      toggleLoading(true);
      const userId = localStorage.getItem("userId");
      if (userId) {
        await firebase
          .firestore()
          .collection("users")
          .doc(userId).onSnapshot(async (user) => {
            if (user.exists) {
              setUserDetails({
                ...user.data(),
                id: user.id,
              });
              await setPlanFeatures(user.data());
              toggleLoading(false);
            }
          })

      }
      toggleLoading(false);
    }

    async function setPlanFeatures(userData) {
      switch (userData.plan) {
        case "Free":
          setFeatures({
            caseNumber: 2,
            templateNumber: 2,
            talentPerCase: 5,
            refereeNumber: 5,
            emailTemplates: 3,
            isVideo: false,
            isAudio: false,
            isEmail: false,
          });
          break;
        case "Basic":
          setFeatures({
            caseNumber: 10,
            templateNumber: 5,
            talentPerCase: -1,
            refereeNumber: -1,
            emailTemplates: 3,
            isVideo: false,
            isAudio: false,
            isEmail: true,
          });
          break;
        case "Pro":
          setFeatures({
            caseNumber: 20,
            templateNumber: -1,
            talentPerCase: -1,
            refereeNumber: -1,
            emailTemplates: -1,
            isVideo: true,
            isAudio: false,
            isEmail: true,
          });
          break;
        case "Enterprise":
          setFeatures({
            caseNumber: -1,
            templateNumber: -1,
            talentPerCase: -1,
            refereeNumber: -1,
            emailTemplates: -1,
            isVideo: true,
            isAudio: false,
            isEmail: true,
          });
          break;
        default:
          setFeatures({
            caseNumber: 2,
            templateNumber: 2,
            talentPerCase: 5,
            refereeNumber: 5,
            emailTemplates: 3,
            isVideo: false,
            isAudio: false,
            isEmail: false,
          });
      }
    }
    getUserDetails();
  }, []);

  async function updateUserDetails(update) {
    const userId = localStorage.getItem("userId");
    await firebase
      .firestore()
      .collection("users")
      .doc(userId)
      .update(update);
  }

  const value = {
    userDetails,
    features,
    loading,
    updateUserDetails,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
