import React from "react";
import Lottie from "lottie-react";
import complete from "../../assets/animations/complete.json";
import usePageTracking from "../../hooks/usePageTracking";

export default function CompleteQuestionnaire() {
  usePageTracking();
  return (
    <div className="flex flex-row min-h-screen justify-center items-center">
      <div>
        <Lottie
          animationData={complete}
          style={{ width: 300, height: 300 }}
          loop={false}
        />
        <p className="text-sm text-center text-navy-700 dark:text-white font-bold">
          You can close this tab now.
        </p>
      </div>
    </div>
  );
}
