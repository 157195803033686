export const pricing = {
  title: "Pricing",
  items: [
    {
      name: "Free",
      planId: "price_1OO3a4Hspq6rNs8844bQ2lTq",
      price: 0,
      priceDetails: "Per month",
      features: [
        "Max of 2 cases",
        "Max of 2 templates",
        "Max of 5 talents per case",
        "Max of 5 referees per case",
        "No Email notifications",
        "No media based verification for referees",
        "Basic Support (72 hours response time)",
      ],
    },
    {
      name: "Basic",
      planId: "price_1OLNVtHspq6rNs88SQ76fRiL",
      price: 5,
      priceDetails: "Per month",
      features: [
        "Max of 10 cases",
        "Max of 5 templates",
        "Unlimited number of talents per case",
        "Unlimited number of referees per case",
        "No Email notifications",
        "No media based verification for referees",
        "Basic Support (48 hours response time)",
      ],
    },
    {
      name: "Pro",
      planId: "price_1OLNWOHspq6rNs88etMVAT3g",
      price: 15,
      priceDetails: "Per month",
      features: [
        "Max of 20 cases",
        "Unlimited templates",
        "Unlimited number of talents per case",
        "Unlimited number of referees per case",
        "Email notifications",
        "Media based verification for referees",
        "Basic Support (24 hours response time)",
      ],
    },
    {
      name: "Enterprise",
      planId: "price_1OO3ZiHspq6rNs88YaRdcYmm",
      price: 30,
      priceDetails: "Per month",
      features: [
        "Unlimited cases",
        "Unlimited templates",
        "Unlimited number of talents per case",
        "Unlimited number of referees per case",
        "Email notifications",
        "Media based verification for referees (AI and Video)",
        "Basic Support (2 hours response time)",
      ],
    },
  ],
};
