import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Toggle from "./Toggle";
import { Collapse } from "antd";

export default function FormRadio({
  formLabel = "Label",
  updateFunction,
  deleteFunction,
  id,
  type = "radio",
  formRequired = false,
  isPreview = false,
  elementName,
  formChecked = false,
}) {
  const [checked, toggleCheck] = useState(formChecked);
  const [, setshowMenu] = useState(false);
  const [, setShowOptions] = useState(false);
  const [richText, setRichText] = useState(formLabel);
  const [isRequired, toggleIsRequired] = useState(formRequired);
  const items = [
    {
      key: "1",
      label: "Set radio properties",
      extra: (
        <MdDelete
          onClick={() => deleteFunc(id)}
          style={{ marginTop: 4 }}
          className="ml-2 text-red-700"
        />
      ),
      children: (
        <div className="flex flex-col mt-3 w-96">
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Input label
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <div className="mt-4">
            <Toggle value={isRequired} changeFunction={toggleIsRequired} />
          </div>
        </div>
      ),
    },
  ];
  const onChange = (key) => {
    // console.log(key);
  };
  const update = () => {
    const obj = {
      label: richText,
      value: checked,
      type,
      id,
      isRequired,
    };
    updateFunction(obj);
  };

  const deleteFunc = (id) => {
    deleteFunction(id);
  };
  return (
    <div
      name={elementName}
      className={`my-3 h-fit cursor-pointer ${
        !isPreview && "border border-solid border-blueGray-100"
      } p-2 pl-5`}
      key={id}
      onMouseLeave={() => {
        setShowOptions(false);
        setshowMenu(false);
        update();
      }}
      onMouseEnter={() => {
        if (isPreview) return;
        setShowOptions(true);
      }}
    >
      <div className="flex flex-row">
        <input
          type="radio"
          id={id}
          defaultValue={checked}
          checked={checked}
          onChange={(e) => toggleCheck(e.target.checked)}
          className="w-5 h-5 bg-white text-sm outline-none border-gray-500"
        />
        <div className="ml-3">
          {richText && (
            <div dangerouslySetInnerHTML={{ __html: `${richText}` }} />
          )}
        </div>
      </div>
      {!isPreview && (
        <div className="w-96 mt-4">
          <Collapse
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
      )}
      {/* {showOptions && (
        <div className="flex flex-row mt-3">
          <FaEdit onClick={() => setshowMenu(!showMenu)} />
          <MdDelete
            onClick={() => deleteFunc(id)}
            className="ml-2 text-red-700"
          />
        </div>
      )} */}
      {/* {showMenu && (
        <div className="flex flex-col mt-3 w-96">
          <small className="font-bold mb-3 text-xl text-center">
            Edit details
          </small>
          <label
            htmlFor="inputlabel"
            className="text-sm text-navy-700 dark:text-white font-bold mt-3"
          >
            Input label
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={richText}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRichText(data);
            }}
          />
          <div className="mt-4">
            <Toggle value={isRequired} changeFunction={toggleIsRequired} />
          </div>
        </div>
      )} */}
    </div>
  );
}
