import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { message } from "antd";
import firebase from "firebase/app";
import Modal from "../../components/Modal";
import EmailTemplateViewer from "../../features/EmailTemplateViewer";
import usePageTracking from "../../hooks/usePageTracking";
import "firebase/auth";
import "firebase/firebase-firestore";

export default function NewEmailTemplate() {
  usePageTracking();
  const [richText, setRichText] = useState("<p>Enter email body here. </p>");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailTemplateName, setEmailTemplateName] = useState(null);
  const [viewEmailTemplate, setViewEmailTemplate] = useState(false);

  useEffect(() => {
    return () => {
      setRichText(null)
    };
  }, [])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await saveEmailTemplate();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function saveEmailTemplate() {
    try {
      if (!richText || richText.length === 0) {
        message.error("You cannot save an empty template");
        return;
      }
      if (!emailTemplateName || emailTemplateName.length === 0) {
        message.error("Please enter a title before saving");
        return;
      }
      message.loading("Saving email template");
      await firebase
        .firestore()
        .collection("email-templates")
        .doc(`${firebase.auth().currentUser.uid}`)
        .collection("list")
        .add({
          title: emailTemplateName,
          content: richText,
        });
      message.success(
        "Template saved successfully. You can now resuse this when reaching out to talents."
      );
      window.history.back();
    } catch (error) {
      message.error("Unable to save template");
    }
  }
  return (
    <div className="relative md:ml-64 bg-blueGray-100">
      {viewEmailTemplate && (
        <EmailTemplateViewer
          isModalOpen={viewEmailTemplate}
          content={richText}
          handleCancel={() => setViewEmailTemplate(false)}
        />
      )}
      {isModalOpen && (
        <Modal
          handleCancel={handleCancel}
          handleOk={handleOk}
          showModal={isModalOpen}
          title="Save template"
        >
          <div>
            <input
              className="border-2 rounded-md px-5 mx-1 h-10"
              placeholder="Enter template name"
              value={emailTemplateName}
              onChange={(e) => setEmailTemplateName(e.target.value)}
            />
            <div className="flex flex-row mt-5 justify-evenly">
              <button
                onClick={handleCancel}
                className="bg-red-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={handleOk}
                className="bg-green-500 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 mt-1 ease-linear transition-all duration-150"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div
        className="lg:ml-12 mb-10 lg:mt-12 cursor-pointer lg:block hidden"
        onClick={() => window.history.back()}
      >
        <i className="fas fa-arrow-left text-emerald-500 mr-4 ml-2"></i>
        <span className="text-sm font-bold">Go back</span>
      </div>
      <div className="flex justify-center">
        <div>
          <div className="flex flex-row justify-evenly mb-4">
            <button
              onClick={() => setViewEmailTemplate(true)}
              className="bg-blue-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 mt-5"
            >
              <i className="fas fa-eye text-white mr-4 ml-4"></i>
            </button>
            <button
              onClick={() => showModal()}
              className="bg-green-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 mt-5"
            >
              <i className="fas fa-save text-white mr-4 ml-4"></i>
            </button>
            <button
              onClick={() => setRichText("")}
              className="bg-red-400 text-white active:bg-emerald-600 w-fit font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 mt-5"
            >
              <i className="fas fa-trash text-white mr-4 ml-4"></i>
            </button>
          </div>
          <ReactQuill
            style={{ height: 500 }}
            theme="snow"
            value={richText}
            onChange={setRichText}
          />
        </div>
      </div>
    </div>
  );
}
