import React, { useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BillingOverview from "../../features/billing/BillingOverview";
import BillingHistory from "../../features/billing/BillingHistory";
import Loading from "../../components/Loading";
import * as Sentry from "@sentry/react";
import firebase from "firebase/app";
import usePageTracking from "../../hooks/usePageTracking";
import "react-tabs/style/react-tabs.css";
import "firebase/firebase-firestore";
import "firebase/auth";

export default function Billings() {
  usePageTracking();
  const { userDetails, loading: contextLoading } = useUserContext();
  const [invoices, setInvoices] = useState([]);
  const [loading, toggleLoading] = useState(contextLoading);

  async function getInvoices() {
    try {
      toggleLoading(true);
      const userInvoices = await firebase
        .firestore()
        .collection("invoices")
        .doc(firebase.auth().currentUser.uid)
        .collection("list")
        .get();
      if (!userInvoices.empty) {
        const arr = [];
        userInvoices.forEach((invoice) => {
          arr.push({
            ...invoice.data(),
            id: invoice.id,
          });
        });
        setInvoices(arr);
        toggleLoading(false);
      }
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    getInvoices();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="relative md:ml-64 p-12">
      <p className="mr-auto text-2xl text-navy-700 dark:text-white font-bold mb-8">
        Billing
      </p>
      <Tabs>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanel>
          <BillingOverview userDetails={userDetails} invoices={invoices} />
        </TabPanel>
        <TabPanel>
          <BillingHistory invoices={invoices} />
        </TabPanel>
      </Tabs>
    </div>
  );
}
