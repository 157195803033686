import firebase from "firebase/app";
import "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyD8Jpsmknct4oWTTKBiSofWgtI2Fi6aH-M",
  authDomain: "iref-admin.firebaseapp.com",
  projectId: "iref-admin",
  storageBucket: "iref-admin.appspot.com",
  databaseURL: "https://iref-admin-default-rtdb.firebaseio.com",
  messagingSenderId: "531205359152",
  appId: "1:531205359152:web:c98868cae96a272db4cff5",
  measurementId: "G-8T9GF1N6HN",
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
